import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const FacebookIcon = (props) => {
    return (
        <SvgIcon {...props} >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M8 0C3.5912 0 0 3.5912 0 8C0 12.4088 3.5912 16 8 16C12.4088 16 16 12.4088 16 8C16 3.5912 12.4088 0 8 0ZM8 1.6C11.5441 1.6 14.4 4.4559 14.4 8C14.4 11.2116 12.0523 13.8504 8.975 14.3188V9.90781H10.8375L11.1297 8.01562H8.975V6.98125C8.975 6.19485 9.23119 5.49688 9.96719 5.49688H11.1484V3.84531C10.9404 3.81731 10.5015 3.75625 9.67188 3.75625C7.93907 3.75625 6.92344 4.67145 6.92344 6.75625V8.01562H5.14219V9.90781H6.92344V14.3031C3.89623 13.7927 1.6 11.1762 1.6 8C1.6 4.4559 4.4559 1.6 8 1.6Z" fill="currentColor"/>
            </svg>
        </SvgIcon>
    );
};

export default FacebookIcon;