import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const EpicIcon = (props) => {
    return (
        <SvgIcon {...props} >
            <svg 
                width="19" 
                height="17" 
                viewBox="0 0 19 17" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
            >
                <path 
                    fillRule="evenodd" 
                    clipRule="evenodd" 
                    d="M2.46709 9.06934L5.23207 2.4334H14.08L16.845 9.06934H2.46709ZM0.808107 9.14566V11.5578C0.808107 12.6262 1.6752 13.4933 2.74359 13.4933H3.02009V14.3228C3.02009 15.3912 3.88719 16.2583 4.95557 16.2583H14.3565C14.5765 16.2583 14.7875 16.1709 14.943 16.0153C15.0986 15.8598 15.186 15.6488 15.186 15.4288C15.186 15.2088 15.0986 14.9978 14.943 14.8423C14.7875 14.6867 14.5765 14.5993 14.3565 14.5993H4.95557C4.88224 14.5993 4.81191 14.5702 4.76006 14.5183C4.70821 14.4665 4.67908 14.3961 4.67908 14.3228V13.4933H16.0155C16.2355 13.4933 16.4465 13.4059 16.602 13.2504C16.7576 13.0948 16.845 12.8838 16.845 12.6638C16.845 12.4438 16.7576 12.2328 16.602 12.0773C16.4465 11.9217 16.2355 11.8343 16.0155 11.8343H2.74359C2.67026 11.8343 2.59993 11.8052 2.54808 11.7533C2.49622 11.7015 2.46709 11.6312 2.46709 11.5578V10.7283H16.845C17.1181 10.7284 17.3871 10.661 17.628 10.5322C17.8688 10.4033 18.0741 10.217 18.2257 9.98974C18.3772 9.76247 18.4703 9.5013 18.4967 9.22941C18.523 8.95752 18.4818 8.68333 18.3768 8.43119L15.4691 1.4546C15.3851 1.25314 15.2433 1.08105 15.0616 0.960012C14.88 0.838973 14.6666 0.774396 14.4483 0.774414H4.86378C4.64549 0.774396 4.43208 0.838973 4.25042 0.960012C4.06876 1.08105 3.92699 1.25314 3.84295 1.4546L0.935296 8.43119C0.840621 8.65702 0.797183 8.90103 0.808107 9.14566Z" 
                    fill="currentColor"
                />
            </svg>
        </SvgIcon>
    );
};

export default EpicIcon;