import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const MenuIcon = (props) => {
    return (
        <SvgIcon {...props} >
            <svg width="16" height="10" viewBox="0 0 16 10" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 9.65258H16V8.04382H0V9.65258ZM0 5.63067H16V4.02191H0V5.63067ZM0 0V1.60876H16V0H0Z" fill="currentColor"/>
            </svg>
        </SvgIcon>
    );
};

export default MenuIcon;