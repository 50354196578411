import React from 'react';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { Icon } from '@arandu/laravel-mui-admin';

import Timer from './Timer';


const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: 7.5,
    right: 7.5,
}));

const TimeTrackerTitleDialog = styled(DialogTitle)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 40,
    fontWeight: 700
}));


const TimeTrackerDialog = ({ 
    open, onClose, onTimerFinished, 
    small = false,
    ...props
}) => {
    return (
        <CustomDialog 
            open={open}
            onClose={onClose}
            fullWidth={true}
            maxWidth="md"
            {...props}
        >
            <CloseButton
                onClick={onClose}
                aria-label="delete"
                size="large"
            >
                <CloseIcon fontSize="inherit" />
            </CloseButton>

            {!small && (
                <TimeTrackerTitleDialog sx={{ pt: 1, pb: 1.25 }} >
                    <Icon name="clock" fontSize='24' sx={{ mr: 1 }} />
                    Contador de Horas
                </TimeTrackerTitleDialog>
            )}

            <Divider sx={{ width: 530 }} />

            <DialogContent sx={{ pt: 1.25 }} >
                <Timer 
                    onTimerFinished={onTimerFinished}
                    small={small} 
                />
            </DialogContent>
        </CustomDialog>
    );
};

export default TimeTrackerDialog;

