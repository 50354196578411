import React from 'react';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { Icon } from '@arandu/laravel-mui-admin';

import Timer from './Timer';


const TimeBox = styled(Paper)(() => ({
    borderRadius: 5,
    padding: 16,
    // marginTop: 8,
}));

const Title = styled(Typography)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 40,
    fontWeight: 700
}));


const TimeTrackerBox = ({ 
    onTimeTrackerClick,
    onTimerFinished,
    small = false 
}) => {
    return (
        <TimeBox>
            {!small && (
                <Title>
                    <Icon name="clock" fontSize='24' sx={{ mr: 1 }} />
                    Contador de Horas
                </Title>
            )}

            <Timer
                onTimeTrackerClick={onTimeTrackerClick}
                onTimerFinished={onTimerFinished}
                small={small}
            />
        </TimeBox>
    );
};

export default TimeTrackerBox;

