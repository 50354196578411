
import _ from 'lodash';
window._ = _;

import axios from 'axios';
window.axios = axios;

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

/* * */

// Enable pusher logging - don't include this in production
Pusher.logToConsole = false;
Pusher.testEvent = false;

window.Pusher = Pusher;
 
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    forceTLS: true,
    // logToConsole: true, // debug
});

if (Pusher.testEvent) {
    window.Echo.private('test.event.channel')
        .listen('TestEvent', (e) => {
            console.log('test event received', e);
        });
}

console.log('bootstrap.js loaded...');