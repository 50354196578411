import React from 'react';

import { config } from '@arandu/laravel-mui-admin';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

import { colors as colorsStatuses } from '../../constants/TaskStatuses';

import TaskTag from '../Layout/TaskTag/TaskTag';
import { ItemTag } from '../Layout/TaskTag/TaskTag';

const CustomStackCell = styled(Stack)(({isTablet}) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start !important',
    width: !isTablet && ('100%'),
    fontWeight: 600,
    textAlign: 'center',
    //
    '& p.title': {
        color: '#191919',
        opacity: '0.56',
        width: isTablet && (500), // '80%',
        fontSize: 14,
        fontWeight: isTablet ? 400 : 500,
        textAlign: 'start !important',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: !isTablet && ('-webkit-box'),
        WebkitLineClamp: !isTablet && (1),
        whiteSpace: isTablet && ('nowrap'),
    }
}));

const TaskNameCell =  ({item}) => {
    const taskStatuses = config('boot.data.constants.taskStatuses');

    const isTablet = useMediaQuery(theme => theme.breakpoints.up('md'));

    const status = item?.attributes?.status;

    const statusTag = {
        name: 'status',
        type: 'text',
        value: taskStatuses[item.status],
        label: taskStatuses[item.status],
        height: 25,
        fontSize: 13,
        radius: 5,
        style: {
            color: colorsStatuses(status).color,
            backgroundColor: colorsStatuses(status).background,
        },
    };

    return (
        <CustomStackCell useFlexGap  isTablet={isTablet}>
            <Stack
                direction={isTablet ? 'row' : 'column'}
                gap={isTablet && (1.75)}
                sx={{width: '100%'}}
                alignItems={isTablet && ('center')}
            >
                <Stack direction="row" justifyContent="space-between" sx={{width: '100%', mb: !isTablet && (.75)}}>
                    <TaskTag
                        task={item} 
                        showTags={{ status: false }}
                        tagProps={{
                            radius: 4,
                            height: 24,
                            width: 25,
                            fontSize: 12,
                            padding: '8px',
                        }}
                    />

                    {!isTablet && (
                        <ItemTag
                            tag={statusTag}
                            {...statusTag}
                        />
                    )}
                </Stack>

                <div style={{flexGrow: 1,}}>
                    <Typography className="title" >
                        {item.name}
                    </Typography>
                </div>
            </Stack>
        </CustomStackCell>
    );
};

export default TaskNameCell;