import app from './app';
import boot from './boot.json';
import date from './date';
import dropzone from './dropzone';
import icons from './icons';
import lang from './lang';
import menu from './menu';
import routes from './routes';
import sentry from './sentry';
import theme from './theme';

export default {
    app,
    boot,
    date,
    dropzone,
    icons,
    lang,
    menu,
    routes,
    sentry,
    theme,
};
