import React from 'react';

import { CsrfToken, route } from '@arandu/laravel-mui-admin';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

import useCurrentUser from '../hooks/useCurrentUser';

const ChangeActiveRoleForm = (props) => {

    const formRef = React.useRef();

    const user = useCurrentUser();

    const currentRole = React.useMemo(() => user.currentRole(), []);

    const handleRoleChange = async () => {
        setTimeout(() => {
            formRef.current.submit();
        }, 500);
    };

    return (
        user.roles.length >= 2
            ? (
                <Box {...props}>
                    <form ref={formRef} method='POST' action={route('admin.user.redirectRole')}>
                        <CsrfToken />
                        <FormControl fullWidth>
                            <Select
                                variant='filled'
                                size='small'
                                name="role_name"
                                id="demo-simple-select"
                                defaultValue={currentRole}
                                onChange={handleRoleChange}
                            >
                                {user.roles.map((role, index) => (
                                    <MenuItem key={index} value={role.name}>{String(role.name)}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </form>
                </Box>
            ) 
            : null
    );
};

export default ChangeActiveRoleForm;
