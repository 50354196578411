import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const RoundedCheckIcon = (props) => {
    return (
        <SvgIcon {...props} >
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 0.350586C5.37262 0.350586 0 5.72321 0 12.3506C0 18.9783 5.37262 24.3506 12 24.3506C18.6278 24.3506 24 18.9783 24 12.3506C24 5.72321 18.6278 0.350586 12 0.350586ZM12 22.8742C6.21037 22.8742 1.5 18.1402 1.5 12.3505C1.5 6.56091 6.21037 1.85054 12 1.85054C17.7896 1.85054 22.5 6.56094 22.5 12.3505C22.5 18.1401 17.7896 22.8742 12 22.8742ZM16.7891 7.95971L9.74848 15.0446L6.57785 11.874C6.28498 11.5811 5.81023 11.5811 5.51698 11.874C5.2241 12.1668 5.2241 12.6416 5.51698 12.9345L9.2291 16.647C9.52198 16.9395 9.99673 16.9395 10.29 16.647C10.3237 16.6132 10.3526 16.5764 10.3789 16.5382L17.8504 9.02056C18.1429 8.72769 18.1429 8.25294 17.8504 7.95971C17.5571 7.66684 17.0824 7.66684 16.7891 7.95971Z" fill="#1D9798"/>
            </svg>
        </SvgIcon>
    );
};

export default RoundedCheckIcon;