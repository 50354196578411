import React from 'react';


import { config, addFilter } from '@arandu/laravel-mui-admin';

import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PopoverAvatar from '../../components/Layout/PopoverAvatar';

const CustomStackCell = styled(Stack)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
}));

export default () => {
    const leadStatuses = config('boot.data.constants.leadStatuses');

    addFilter('repository_index_lead_column_users_content', (_, item) => {
        return (
            <CustomStackCell>
                <PopoverAvatar users={item.users} sx={{ height: 28, width: 28 }} />
            </CustomStackCell>
        );
    });

    addFilter('repository_index_item_data', (value, item, column) => {
        if (item.className === 'lead' && column.key === 'status') {
            return (
                <Typography color="primary" fontWeight="bold">
                    {leadStatuses[value]}
                </Typography>
            );
        }
        return value;
    });
    
};

