import { addFilter, macros } from '@arandu/laravel-mui-admin';



export default () => {

    // Método para obter o papel ativo do usuário
    addFilter('model_user_call_currentRole', (_, user) => (role = undefined) => {
        const getRoleName = () => {
            if (user.attributes.active_role && user.relations.roles.map(role => role.id).includes(user.attributes.active_role)) {
                return user.relations.roles.find(role => role.id === user.attributes.active_role).name;
            }
            return user.relations.roles[0].name;
        };

        if (typeof role === 'undefined') {
            return getRoleName();
        }

        if (typeof role === 'string') {
            return getRoleName() === role;
        }

        throw new TypeError('user.currentRole() Invalid argument type');
    });
};
