import { route } from '@arandu/laravel-mui-admin';
import axios from 'axios';
import React from 'react';

const KeepOnlineProvider = ({ children }) => {

    React.useEffect(() => {
        setInterval(() => {
            // Tell the server that this user is online
            axios.post(route('api.user.ping'));
        }, 60 * 1000);
    }, []);


    return children;

};


export default KeepOnlineProvider;