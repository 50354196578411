import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const SubtaskIcon = (props) => {
    return (
        <SvgIcon {...props} >
            <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.5 3.75H7M1 0.75H4M2.5 0.75V9C2.5 9.19891 2.57902 9.38968 2.71967 9.53033C2.86032 9.67098 3.05109 9.75 3.25 9.75H7M7 3C7 2.80109 7.07902 2.61032 7.21967 2.46967C7.36032 2.32902 7.55109 2.25 7.75 2.25H12.25C12.4489 2.25 12.6397 2.32902 12.7803 2.46967C12.921 2.61032 13 2.80109 13 3V4.5C13 4.69891 12.921 4.88968 12.7803 5.03033C12.6397 5.17098 12.4489 5.25 12.25 5.25H7.75C7.55109 5.25 7.36032 5.17098 7.21967 5.03033C7.07902 4.88968 7 4.69891 7 4.5V3ZM7 9C7 8.80109 7.07902 8.61032 7.21967 8.46967C7.36032 8.32902 7.55109 8.25 7.75 8.25H12.25C12.4489 8.25 12.6397 8.32902 12.7803 8.46967C12.921 8.61032 13 8.80109 13 9V10.5C13 10.6989 12.921 10.8897 12.7803 11.0303C12.6397 11.171 12.4489 11.25 12.25 11.25H7.75C7.55109 11.25 7.36032 11.171 7.21967 11.0303C7.07902 10.8897 7 10.6989 7 10.5V9Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    );
};

export default SubtaskIcon;