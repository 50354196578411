import { config } from '@arandu/laravel-mui-admin';

export default {
    transformSrc: (src, options) => {
        // if (options.uploadId === 'image') {
        //     if (config('boot.data.filesystemDisk') === 's3' && options.name === 'avatar_src' && options.data?.files?.avatar_src) {
        //         return options.data.files.avatar_src;
        //     }
        //     return `/storage/images/${src}`;
        // }
        // if (options.uploadId === 'file') {
        //     return `/storage/files/${src}`;
        // }

        return options.data.files[options.name];
    }
};
