import React from 'react';

import { addFilter, t } from '@arandu/laravel-mui-admin';
import { Typography } from '@mui/material';
import { applyCurrencyFormat } from '../common';

const valueCurrencyFormatOptions = {
    isDebit: (item) => [2, 3].includes(item.type),
};

export default () => {
    /** ROLE. */
    addFilter('repository_index_item_data', (value, item, column) => {

        if (item.className === 'transaction') {
            if (column.key === 'transactionable') {
                if (!value) {
                    return '(Sem referência)';
                }
                return (
                    <Typography>
                        <em>{t(`models.${value.className}.singular`)}: </em>
                        {value.name}
                    </Typography>
                );
            }

            if (['due_date', 'pay_date'].includes(column.key)) {
                if (!value) {
                    return '-';
                }

                return value.format('ll');
            }
        }

        return value;
    });

    addFilter('repository_index_item_data', applyCurrencyFormat('transaction', 'value', valueCurrencyFormatOptions));

    addFilter('repository_index_tabs', (tabs, className) => {
        if (className === 'transaction') {
            return [
                ...tabs,
                {
                    label: 'Pagas',
                    name: 'paid',
                },
                {
                    label: 'À vencer',
                    name: 'to_pay',
                },
                {
                    label: 'Vencidas',
                    name: 'overdue',
                },
            ];
        }

        return tabs;
    });


};
