import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const FilterIcon = (props) => {
    return (
        <SvgIcon {...props} >
            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1H18L12 9V19L7 15V9L1 1Z" stroke="#191919" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    );
};

export default FilterIcon;