import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const TrashIcon = (props) => {
    return (
        <SvgIcon {...props} >
            <svg 
                width="23" 
                height="25" 
                viewBox="0 0 23 25" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
            >
                <path 
                    d="M21.5004 4.54545H17.4094V3.18182C17.4094 2.33795 17.0742 1.52864 16.4775 0.931933C15.8808 0.335226 15.0715 0 14.2276 0H8.77308C7.92921 0 7.1199 0.335226 6.5232 0.931933C5.92649 1.52864 5.59126 2.33795 5.59126 3.18182V4.54545H1.50036C1.1387 4.54545 0.79185 4.68912 0.536119 4.94485C0.280387 5.20059 0.136719 5.54743 0.136719 5.90909C0.136719 6.27075 0.280387 6.6176 0.536119 6.87333C0.79185 7.12906 1.1387 7.27273 1.50036 7.27273H1.9549V22.7273C1.9549 23.33 2.19435 23.9081 2.62057 24.3343C3.04679 24.7606 3.62486 25 4.22763 25H18.7731C19.3758 25 19.9539 24.7606 20.3801 24.3343C20.8064 23.9081 21.0458 23.33 21.0458 22.7273V7.27273H21.5004C21.862 7.27273 22.2089 7.12906 22.4646 6.87333C22.7203 6.6176 22.864 6.27075 22.864 5.90909C22.864 5.54743 22.7203 5.20059 22.4646 4.94485C22.2089 4.68912 21.862 4.54545 21.5004 4.54545ZM8.31854 3.18182C8.31854 3.06127 8.36643 2.94565 8.45167 2.86041C8.53691 2.77516 8.65253 2.72727 8.77308 2.72727H14.2276C14.3482 2.72727 14.4638 2.77516 14.549 2.86041C14.6343 2.94565 14.6822 3.06127 14.6822 3.18182V4.54545H8.31854V3.18182ZM18.3185 22.2727H4.68217V7.27273H18.3185V22.2727ZM10.1367 10.9091V18.1818C10.1367 18.5435 9.99305 18.8903 9.73732 19.1461C9.48159 19.4018 9.13474 19.5455 8.77308 19.5455C8.41142 19.5455 8.06458 19.4018 7.80885 19.1461C7.55311 18.8903 7.40945 18.5435 7.40945 18.1818V10.9091C7.40945 10.5474 7.55311 10.2006 7.80885 9.94485C8.06458 9.68912 8.41142 9.54545 8.77308 9.54545C9.13474 9.54545 9.48159 9.68912 9.73732 9.94485C9.99305 10.2006 10.1367 10.5474 10.1367 10.9091ZM15.5913 10.9091V18.1818C15.5913 18.5435 15.4476 18.8903 15.1919 19.1461C14.9361 19.4018 14.5893 19.5455 14.2276 19.5455C13.866 19.5455 13.5191 19.4018 13.2634 19.1461C13.0077 18.8903 12.864 18.5435 12.864 18.1818V10.9091C12.864 10.5474 13.0077 10.2006 13.2634 9.94485C13.5191 9.68912 13.866 9.54545 14.2276 9.54545C14.5893 9.54545 14.9361 9.68912 15.1919 9.94485C15.4476 10.2006 15.5913 10.5474 15.5913 10.9091Z" 
                    fill="currentColor"
                />
            </svg>
        </SvgIcon>
    );
};

export default TrashIcon;