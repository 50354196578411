
import React, { useEffect } from 'react';
import { registerLicense } from '@syncfusion/ej2-base';

const LICENSE_KEY = 'ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Ud0RiW3xdc3FdRGRe'; // Substitua pela sua chave de licença real

export const SyncfusionContext = React.createContext({});

const SyncfusionProvider = ({ children }) => {
    useEffect(() => {
        registerLicense(LICENSE_KEY);
    }, []);

    return (
        <SyncfusionContext.Provider value={{}}>
            {children}
        </SyncfusionContext.Provider>
    );
};

export default SyncfusionProvider;
