import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const TaskCheckIcon = (props) => {
    return (
        <SvgIcon {...props} >
            <svg 
                width="18" 
                height="15" 
                viewBox="0 0 18 15" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
            >
                <path 
                    d="M7.53409 8.30597L4.13352 5.01493L3 6.11194L7.53409 10.5L17.25 1.09702L16.1165 0L7.53409 8.30597Z" 
                    fill="#C4C3C3"/>
                <circle 
                    cx="7.5" 
                    cy="7.5" 
                    r="6.75" 
                    stroke="#C4C3C3" 
                    strokeWidth="1.5"
                />
            </svg>
        </SvgIcon>
    );
};

export default TaskCheckIcon;