import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const TaskIcon = (props) => {
    return (
        <SvgIcon {...props} >
            <svg 
                width="15" 
                height="18" 
                viewBox="0 0 15 18" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
            >
                <path 
                    d="M6.65503 14.4L11.74 9.315L10.435 8.01L6.63253 11.8125L4.74253 9.9225L3.46003 11.205L6.65503 14.4ZM2.20003 18C1.70503 18 1.28113 17.8236 0.928326 17.4708C0.575526 17.118 0.399426 16.6944 0.400026 16.2V1.8C0.400026 1.305 0.576426 0.881101 0.929226 0.528301C1.28203 0.175501 1.70563 -0.000598472 2.20003 1.52801e-06H9.40003L14.8 5.4V16.2C14.8 16.695 14.6236 17.1189 14.2708 17.4717C13.918 17.8245 13.4944 18.0006 13 18H2.20003ZM8.50003 6.3V1.8H2.20003V16.2H13V6.3H8.50003Z" 
                    fill="currentColor"
                />
            </svg>
        </SvgIcon>
    );
};

export default TaskIcon;