

import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const BoardIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="45" viewBox="0 0 44 45" fill="none">
                <path d="M37.5556 2.65234H6.44444C3.98985 2.65234 2 4.64219 2 7.09679V38.2079C2 40.6625 3.98985 42.6523 6.44444 42.6523H37.5556C40.0102 42.6523 42 40.6625 42 38.2079V7.09679C42 4.64219 40.0102 2.65234 37.5556 2.65234Z" stroke="currentColor" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M17.5553 11.543H10.8887V31.543H17.5553V11.543Z" stroke="currentColor" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M33.111 11.543H26.4443V22.6541H33.111V11.543Z" stroke="currentColor" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    );
};

export default BoardIcon;
