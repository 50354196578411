import { t } from '@arandu/laravel-mui-admin';

import dayjs from 'dayjs';

export const getDateFormat = (date = dayjs(), format = 'YYYY-MM-DD', withTime = false) => {
    if (!dayjs.isDayjs(date)) {
        date = dayjs(date);
    }
    
    if (withTime) {
        return date.format(format + ' HH:mm');
    }
    return date.format(format);
};

export const getWeekDays = (format = null) => {
    const weekDays = [];
    const today = new Date();
    const first = today.getDate() - today.getDay();
    const last = first + 6;

    for (let i = first; i <= last; i++) {
        const day = new Date(today.setDate(i));
        weekDays.push(day);
    }

    if (format) {
        return weekDays.map((day) => day.toLocaleDateString(format));
    }

    return weekDays;
};

export const getWeekBusinessRange = () => {
    const weekDays = getWeekDays('pt-BR');

    const first = weekDays[1];
    const last = weekDays[weekDays.length - 2];

    return `${first.split('/')[0]} - ${last}`;
};

export const getTimeAgo = (date, justNow = false) => {

    const datetime = dayjs(date);

    const diffs = {
        days: dayjs().diff(datetime, 'day'),
        hours: dayjs().diff(datetime, 'hour'),
        minutes: dayjs().diff(datetime, 'minute'),
        seconds: dayjs().diff(datetime, 'second'),
    };

    const formats = {
        day: datetime.format('dddd'),
        month: datetime.format('MMMM'),
        year: datetime.format('YYYY'),
    };
    
    if (diffs.days > 7) {
        return datetime.format('DD/MM/YYYY');
    } else if (diffs.days > 0) {
        return `${formats.day.charAt(0).toUpperCase() + formats.day.slice(1)} ${datetime.format('DD/MM')}`;
    } else if (diffs.hours > 0) {
        return datetime.format('HH:mm');
    } else if (diffs.minutes > 0) {
        return `${diffs.minutes} ${t('common.minutes_ago')}`;
    }

    if (justNow) {
        return t('common.just_now');
    }    
    return `${diffs.seconds} ${t('common.seconds_ago')}`;
};

export const convertNumToTime = (number, options = {}) => {

    const opts = {
        prefix: '',
        separator: ':',
        suffix: '',
        ...options,
    };

    // Check sign of given number
    let sign = (number >= 0) ? 1 : -1;

    // Set positive value of number of sign negative
    number = number * sign;

    // Separate the int from the decimal part
    const hour = Math.floor(number);
    let decPart = number - hour;

    const min = 1 / 60;
    // Round to nearest minute
    decPart = min * Math.round(decPart / min);

    let minute = `${Math.floor(decPart * 60)}`;

    // Add padding if need
    if (minute.length < 2) {
        minute = `0${minute}`; 
    }

    // Add Sign in final result
    sign = sign === 1 ? '' : '-';

    // Return concate hours and minutes
    return `${opts.prefix}${sign}${hour}${opts.separator}${minute}${opts.suffix}`;
};

export const calculateTaskDuration = (task) => {

    const startDate = dayjs(task.createdAt);
    const endDate = dayjs(task.due_date);

    const diffInDays = endDate.diff(startDate, 'days');
    const diffInHours = startDate.diff(endDate, 'hours');

    const estimate = (() => {
        return Math.abs(diffInDays === 0
            ? diffInHours
            : diffInDays
        );
    })();

    const period = (() => {
        return diffInDays === 0
            ? estimate > 1 ? 'hours' : 'hour'
            : estimate > 1 ? 'days' : 'day';
    })();

    return {
        estimate,
        period,
    };
};

export const formatTime = (milliseconds) => {
    const seconds = Math.floor(milliseconds / 1000) % 60;
    const minutes = Math.floor(milliseconds / (1000 * 60)) % 60;
    const hours = Math.floor(milliseconds / (1000 * 60 * 60));

    const padZero = (value) => (value < 10 ? `0${value}` : value);

    return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
};

export const isWithinDaysBefore = (date, daysBefore = 4) => {
    const today = dayjs();
    const isWithinDays = date.isAfter(today.subtract(daysBefore, 'day'), 'day');

    if (isWithinDays) {
        return date.format('dddd');
    }

    return date.format('DD/MM/YY');
};

export const isWithinDaysAfter = (date, daysToCount = 7) => {
    const today = dayjs();
    const nextDays = today.add(daysToCount, 'day');

    const isToday = date.isSame(today, 'day');
    const isTomorrow = date.isSame(today.add(1, 'day'), 'day');
    const isNextDays = date.isAfter(today, 'day') && date.isBefore(nextDays, 'day');

    if (isToday) {
        return 'Hoje';
    }
    if (isTomorrow) {
        return 'Amanhã';
    }
    if (isNextDays) {
        return date.format('dddd');
    }

    return date.format('DD/MM/YY');
};
