import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const UserIconGreen = (props) => {
    return (
        <SvgIcon {...props} >
            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="8" cy="7.5" r="6.75" stroke="#1D9798" strokeWidth="1.5"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M7.78599 3.85736C6.83885 3.85736 6.07171 4.6245 6.07171 5.57165C6.07171 6.51879 6.83885 7.28593 7.78599 7.28593C8.73314 7.28593 9.50028 6.51879 9.50028 5.57165C9.50028 4.6245 8.73314 3.85736 7.78599 3.85736ZM8.64314 5.57165C8.64314 5.10022 8.25742 4.7145 7.78599 4.7145C7.31456 4.7145 6.92885 5.10022 6.92885 5.57165C6.92885 6.04307 7.31456 6.42879 7.78599 6.42879C8.25742 6.42879 8.64314 6.04307 8.64314 5.57165ZM10.3574 9.42879C10.2717 9.1245 8.94314 8.57165 7.78599 8.57165C6.62885 8.57165 5.30028 9.1245 5.21456 9.43307V9.85736H10.3574V9.42879ZM4.35742 9.42879C4.35742 8.28879 6.64171 7.7145 7.78599 7.7145C8.93028 7.7145 11.2146 8.28879 11.2146 9.42879V10.7145H4.35742V9.42879Z" fill="#1D9798"/>
            </svg>
        </SvgIcon>
    );
};

export default UserIconGreen;