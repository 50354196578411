import React from 'react';

import { addFilter, auth } from '@arandu/laravel-mui-admin';

import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

import defaultAvatar from '../../../images/avatar.jpeg';
import { convertNumToTime } from '../../support/date';


export default () => {

    addFilter(
        'repository_index_task_actions',
        (items) => {
            const [create, ...otherActions] = items;
            return otherActions;
        }
    );
    addFilter(
        'repository_index_timesheet_actions',
        (items) => {
            const [create, ...otherActions] = items;
            return otherActions;
        }
    );

    addFilter('repository_form_timesheet_schema', (schema) => {
        if (auth().user().currentRole('developer')) {
            return 'developer';
        }
        return schema;
    });

    addFilter('repository_index_timesheet_column_date_content', (_value, item) => {
        const { started_at: startedAt } = item;

        if (!startedAt || !startedAt.isValid()) {
            return 'N/D';
        }

        return startedAt.format('DD/MM/YYYY');
    });

    addFilter('repository_index_timesheet_column_interval_content', (_value, item) => {
        const { started_at: startedAt, ended_at: endedAt } = item;

        if (
            (!startedAt || !startedAt.isValid()) 
            && 
            (!endedAt || !endedAt.isValid())
        ) {
            return 'N/D';
        } else if (!endedAt || !endedAt.isValid()) {
            return `${startedAt.format('HH:mm')} - N/D`;
        } else if (!startedAt || !startedAt.isValid()) {
            return `N/D - ${endedAt.format('HH:mm')}`;
        }

        return `${startedAt.format('HH:mm')} - ${endedAt.format('HH:mm')}`;
    });

    addFilter('repository_index_timesheet_column_project_content', (_value, item) => {
        const { project } = item;

        return (
            <Chip label={project.name} />
        );
    });

    addFilter('repository_index_timesheet_column_task.name_content', (_, item) => {
        const { task, task_id } = item;

        if (!task || (!task_id || task_id === 0)) {
            return 'N/D';
        }

        return task.name;
    });

    addFilter('repository_index_timesheet_column_avatar_content', (_value, item) => {
        const { user } = item;

        const avatar = user.files.avatar_src
            ? user.files.avatar_src
            : defaultAvatar;

        return (
            <Stack justifyContent="center" alignItems="center">
                <Avatar src={avatar} alt={user.name} />
            </Stack>
        );
    });

    addFilter('repository_index_timesheet_column_start_time_content', (_value, item) => {
        const { started_at: startedAt } = item;

        if (!startedAt || !startedAt.isValid()) {
            return 'N/D';
        }

        return startedAt.format('HH:mm');
    });

    addFilter('repository_index_timesheet_column_end_time_content', (_value, item) => {
        const { ended_at: endedAt } = item;

        if (!endedAt || !endedAt.isValid()) {
            return 'N/D';
        }

        return endedAt.format('HH:mm');
    });

    addFilter('repository_index_timesheet_column_work_time_content', (_value, item) => {
        const { work_time: workTime } = item;

        return convertNumToTime(workTime, { separator: 'h' });
    });

    addFilter('repository_index_timesheet_filter_fields', (fields) => {
        if (auth().user().currentRole('developer')) {
            return fields.filter(({ name }) => name !== 'users');
        }
        return fields;
    });

    addFilter('repository_index_timesheet_table_columns', (columns) => {
        if (auth().user().currentRole('developer')) {
            return columns.filter(({ key }) => key !== 'user.name');
        }
        return columns;
    });


};

