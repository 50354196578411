
import { config } from '@arandu/laravel-mui-admin';
import React from 'react';

const useMenuItems = () => {

    const items = React.useMemo(config('menu.loader'), []);

    return items;
};

export default useMenuItems;
