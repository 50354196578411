import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const ExpandIcon = (props) => {
    return (
        <SvgIcon {...props} >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 11V3H13L16.29 6.29L6.29 16.29L3 13V21H11L7.71 17.71L17.71 7.71L21 11Z" fill="#191919"/>
            </svg>
        </SvgIcon>
    );
};

export default ExpandIcon;