import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const CopyIcon = (props) => {
    return (
        <SvgIcon {...props} >
            <svg 
                width="25" 
                height="25" 
                viewBox="0 0 25 25" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
            >
                <path 
                    d="M23.5 0H7.5C7.10218 0 6.72064 0.158035 6.43934 0.43934C6.15804 0.720644 6 1.10218 6 1.5V6H1.5C1.10218 6 0.720644 6.15804 0.43934 6.43934C0.158035 6.72064 0 7.10218 0 7.5V23.5C0 23.8978 0.158035 24.2794 0.43934 24.5607C0.720644 24.842 1.10218 25 1.5 25H17.5C17.8978 25 18.2794 24.842 18.5607 24.5607C18.842 24.2794 19 23.8978 19 23.5V19H23.5C23.8978 19 24.2794 18.842 24.5607 18.5607C24.842 18.2794 25 17.8978 25 17.5V1.5C25 1.10218 24.842 0.720644 24.5607 0.43934C24.2794 0.158035 23.8978 0 23.5 0ZM16 22H3V9H16V22ZM22 16H19V7.5C19 7.10218 18.842 6.72064 18.5607 6.43934C18.2794 6.15804 17.8978 6 17.5 6H9V3H22V16Z" 
                    fill="currentColor"
                />
            </svg>
        </SvgIcon>
    );
};

export default CopyIcon;