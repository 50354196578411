
import { config } from '@arandu/laravel-mui-admin';


export const colors = (constant) => {
    if (typeof constant === 'string') {
        constant = parseInt(constant);
    }
    
    const taskPriorities = config('boot.data.constants.taskPriorities');
    const priority = taskPriorities[constant];

    switch (priority) {
    case 'Block':
        return {
            color: '#969696',
            background: '#F0F0F0',
        };
    case 'Baixa':
        return {
            color: '#191919',
            background: '#F8F5C9',
        };
    case 'Média':
        return {
            color: '#FA510C',
            background: '#FFE8D5',
        };
    case 'Alta':
        return {
            color: '#FF2C2C',
            background: '#FFDFDF',
        };
    default:
        return {
            color: '#191919',
            background: '#a7a7a7',
        };
    }
};
