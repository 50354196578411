import React from 'react';

import Typography from '@mui/material/Typography';
import { config } from '@arandu/laravel-mui-admin';

export const applyCurrencyFormat = (className, toColumn = 'value', options = {}) => (value, item, column) => {

    if (item.className === className && column.key === toColumn) {
        const { 
            isDebit = () => value < 0,
        } = options;

        return (
            <Typography color={isDebit(item) ? 'red' : 'green'} fontWeight="bold">
                {parseFloat(value).toLocaleString(document.documentElement.lang, { 
                    style: 'currency',
                    currency: config('boot.data.currency.code'),
                })}
            </Typography>
        );
    }

    return value;
};