import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const ProjectGreenIcon = (props) => {
    return (
        <SvgIcon {...props} >
            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3333 12.5H0.666667V5.16667H2V11.1667H10V5.16667H11.3333V12.5ZM0 0.5H12V4.5H0V0.5ZM4.33333 5.83333H7.66667C7.85333 5.83333 8 5.98 8 6.16667V7.16667H4V6.16667C4 5.98 4.14667 5.83333 4.33333 5.83333ZM1.33333 1.83333V3.16667H10.6667V1.83333H1.33333Z" fill="#1D9798"/>
            </svg>
        </SvgIcon>
    );
};

export default ProjectGreenIcon;