import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import 'dayjs/locale/pt-br';
import { ptBR } from '@mui/x-date-pickers/locales';
import dayjs from 'dayjs';

dayjs.locale('pt-br');

export default {
    adapter: AdapterDayjs,
    locale: 'pt-br',
    pickerLocale: ptBR,
    
};