import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const SubItemIcon = (props) => {
    return (
        <SvgIcon {...props} >
            <svg viewBox="0 0 24 54"><g stroke="currentColor" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round"><path d="M8 6v42M20 27H8.3"></path></g></svg>
        </SvgIcon>
    );
};

export default SubItemIcon;