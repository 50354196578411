import React from 'react';

import { ThemeProvider, CssBaseline } from '@mui/material';

import { ToastProvider, DialogProvider } from '@arandu/laravel-mui-admin';
import { RouterProvider } from 'react-router-dom';
import { LocalizationProvider } from '@arandu/lmaplugin-datepicker-input';

import FirebaseProvider from '../providers/FirebaseProvider';
import SyncfusionProvider from '../providers/SyncFusionProvider';

import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/mousewheel';
import KeepOnlineProvider from '../providers/KeepOnlineProvider';

const App = ({ router, theme }) => {

    // This is the main component of your application.
    // Here you can add providers from packages like Redux, React Query, etc.
    // You can also add your own providers here.

    // In order to be able to use the `toast` and `dialog` functions
    // from '@arandu/laravel-mui-admin' you must have the `ToastProvider` and
    // `DialogProvider` in your component tree.

    return (
        <React.StrictMode>
            <LocalizationProvider>
                <ThemeProvider theme={theme}>
                    <FirebaseProvider>
                        <SyncfusionProvider>
                            <KeepOnlineProvider>
                                <CssBaseline />
                                <RouterProvider router={router} />
                                <ToastProvider />
                                <DialogProvider />
                            </KeepOnlineProvider>
                        </SyncfusionProvider>
                    </FirebaseProvider>
                </ThemeProvider>
            </LocalizationProvider>
        </React.StrictMode>
    );
};

export default App;