import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const CopyUrlIcon = (props) => {
    return (
        <SvgIcon {...props} >
            <svg 
                width="25" 
                height="13" 
                viewBox="0 0 25 13" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
            >
                <path 
                    d="M18.75 0.25H13.75V2.75H18.75C20.8125 2.75 22.5 4.4375 22.5 6.5C22.5 8.5625 20.8125 10.25 18.75 10.25H13.75V12.75H18.75C22.2 12.75 25 9.95 25 6.5C25 3.05 22.2 0.25 18.75 0.25ZM11.25 10.25H6.25C4.1875 10.25 2.5 8.5625 2.5 6.5C2.5 4.4375 4.1875 2.75 6.25 2.75H11.25V0.25H6.25C2.8 0.25 0 3.05 0 6.5C0 9.95 2.8 12.75 6.25 12.75H11.25V10.25ZM7.5 5.25H17.5V7.75H7.5V5.25Z" 
                    fill="currentColor"
                />
            </svg>
        </SvgIcon>
    );
};

export default CopyUrlIcon;