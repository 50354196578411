/* eslint-disable quotes */
import React from 'react';

import { addFilter } from '@arandu/laravel-mui-admin';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { serialize } from 'object-to-formdata';
import PopoverAvatar from '../../components/Layout/PopoverAvatar';

const CustomStackCell = styled(Stack)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
}));

export default () => {
    addFilter(
        `model_form_options_notice_default`,
        (options) => ({
            ...options,
            transformPayload: (payload) => serialize(payload),
        }),
    );

    addFilter('repository_index_notice_column_users_content', (_, item) => {
        console.log(item.users);
        return (
            <CustomStackCell>
                <PopoverAvatar users={item.users} sx={{ width: 30, height: 30 }} />
            </CustomStackCell>
        );
    });
};
