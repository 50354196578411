import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const FeedbackIcon = (props) => {
    return (
        <SvgIcon {...props} >
            <svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51" fill="none">
                <path d="M15.0833 33.5924H29.6667V37.7591H15.0833V33.5924ZM15.0833 25.2591H35.9167V29.4258H15.0833V25.2591ZM15.0833 16.9258H35.9167V21.0924H15.0833V16.9258ZM40.0833 8.59245H31.375C30.5 6.17578 28.2083 4.42578 25.5 4.42578C22.7917 4.42578 20.5 6.17578 19.625 8.59245H10.9167C10.625 8.59245 10.3542 8.61328 10.0833 8.67578C9.27083 8.84245 8.54167 9.25911 7.97917 9.82161C7.60417 10.1966 7.29167 10.6549 7.08333 11.1549C6.875 11.6341 6.75 12.1758 6.75 12.7591V41.9258C6.75 42.4883 6.875 43.0508 7.08333 43.5508C7.29167 44.0508 7.60417 44.4883 7.97917 44.8841C8.54167 45.4466 9.27083 45.8633 10.0833 46.0299C10.3542 46.0716 10.625 46.0924 10.9167 46.0924H40.0833C42.375 46.0924 44.25 44.2174 44.25 41.9258V12.7591C44.25 10.4674 42.375 8.59245 40.0833 8.59245ZM25.5 8.07161C26.3542 8.07161 27.0625 8.77995 27.0625 9.63411C27.0625 10.4883 26.3542 11.1966 25.5 11.1966C24.6458 11.1966 23.9375 10.4883 23.9375 9.63411C23.9375 8.77995 24.6458 8.07161 25.5 8.07161ZM40.0833 41.9258H10.9167V12.7591H40.0833V41.9258Z" fill="currentColor"/>
            </svg>
        </SvgIcon>
    );
};

export default FeedbackIcon;