
import React from 'react';

import dayjs from 'dayjs';

import { config, addFilter, auth, Icon } from '@arandu/laravel-mui-admin';

import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

import TaskTag from '../../components/Layout/TaskTag/TaskTag';

import { colors as colorsStatuses } from '../../constants/TaskStatuses';
import PopoverAvatar from '../../components/Layout/PopoverAvatar';
import TaskNameCell from '../../components/ReorderableTable/TaskNameCell';

import { convertNumToTime, isWithinDaysAfter, isWithinDaysBefore } from '../../support/date';

import defaultAvatar from '../../../images/avatar.jpeg';

import { useTranslation } from 'react-i18next';

const CustomStackCell = styled(Stack)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
}));

export default () => {
    

    const taskStatuses = config('boot.data.constants.taskStatuses');
    const taskPriorities = config('boot.data.constants.taskPriorities');

    /* MAIN ATTRIBUTES */

    addFilter('model_task_get_due_date_attribute', (value) => {
        if (!value) {
            return null;
        }

        return dayjs(value);
    });

    /* REORDERABLE TABLE */

    addFilter('reorderable_table_task_filter_columns', (columns, isTablet = true) => {
        if (!isTablet) {
            return [columns[0]];
        }
        return columns;
    });

    addFilter('reorderable_table_item_data', (value) => {
        return value;
    });

    addFilter('reorderable_table_task_column_name_content', (_, item) => {
        return {
            component: (
                <TaskNameCell item={item} />
            ),
            dependentProps: {
                sx: {
                    paddingLeft: '45px !important',
                }
            }
        };
    });

    addFilter('reorderable_table_task_column_users_content', (_, item) => {
        return {
            component: (
                <CustomStackCell>
                    <PopoverAvatar users={item.users} sx={{ width: 30, height: 30 }} />
                </CustomStackCell>
            ),
        };
    });

    addFilter('reorderable_table_task_column_status_content', (_, item) => {
        const style = {
            color: colorsStatuses(item.status).color,
            backgroundColor: colorsStatuses(item.status).background,
        };

        return {
            component: (
                <CustomStackCell sx={{justifyContent: 'left'}}>
                    <Chip
                        size="small"
                        label={taskStatuses[item.status]}
                        sx={{
                            backgroundColor: style.backgroundColor,
                            color: style.color,
                            fontSize: 12,
                            fontWeight: 500,
                            maxHeight: 24,
                            borderRadius: '4px',
                        }}
                    />
                </CustomStackCell>
            ),
        };
    });

    addFilter('reorderable_table_task_column_due_date_content', (_, item) => {
        const { status, due_date: dueDate } = item;
        
        if (!dueDate) {
            return (
                <Typography>
                    Sem data
                </Typography>
            );
        }

        const { withinDays, weekDay } = isWithinDaysAfter(dueDate);

        const isLate = dueDate && dueDate.isBefore(dayjs(), 'day');

        const style = {
            fontSize: 14,
            color: isLate && ![40, 50].includes(status)
                ? '#FF2C2C !important'
                : '#191919 !important',
            opacity: isLate && ![40, 50].includes(status)
                ? '1'
                : '0.56',
        };

        return {
            component: (
                <CustomStackCell>
                    <Typography sx={style}>
                        {!isLate && withinDays
                            ? weekDay
                            : dueDate.format('DD/MM/YY')
                        }
                    </Typography>
                </CustomStackCell>
            ),
        };
    });

    addFilter('reorderable_table_task_column_budget_content', (_, item) => {
        return {
            component: (
                <CustomStackCell>
                    <Typography sx={{
                        fontSize: 14,
                        color: '#191919',
                        opacity: 0.56,
                    }}>
                        {convertNumToTime(item.budget, { separator: 'h' })}
                    </Typography>
                </CustomStackCell>
            ),
        };
    });

    addFilter('reorderable_table_task_column_content', (_, item, key) => {
        return {
            component: (
                <CustomStackCell>
                    <Typography >
                        {item[key]}
                    </Typography>
                </CustomStackCell>
            ),
        };
    });

    /* REPOSITORY INDEX TABLE */

    addFilter('repository_index_task_column_users_content', (_, item) => {
        return (
            <CustomStackCell>
                <PopoverAvatar users={item.users} sx={{ height: 28, width: 28 }} />
            </CustomStackCell>
        );
    });

    addFilter('repository_index_task_column_status_content', (value) => {
        return (
            <CustomStackCell sx={{ justifyContent: 'start !important' }} >
                <Typography>
                    {taskStatuses[value]}
                </Typography>
            </CustomStackCell>
        );
    });

    addFilter('repository_index_task_column_priority_content', (value) => {
        return (
            <CustomStackCell sx={{ justifyContent: 'start !important' }} >
                <Typography>
                    {taskPriorities[value]}
                </Typography>
            </CustomStackCell>
        );
    });

    addFilter('repository_index_task_column_due_date_content', (_, item) => {
        const { due_date: dueDate } = item;

        if (!dueDate) {
            return 'N/D';
        }

        return (
            <CustomStackCell>
                <Typography>
                    {dueDate.format('DD/MM/YYYY')}
                </Typography>
            </CustomStackCell>
        );
    });

    addFilter('repository_index_task_column_budget_content', (_, item) => {
        return (
            <CustomStackCell>
                <Typography>
                    {convertNumToTime(item.budget, { separator: 'h' })}
                </Typography>
            </CustomStackCell>
        );
    });

    // TaskSprint Table

    addFilter('repository_index_task_column_work_time_content', (_, item) => {
        const { timesheets = [] } = item;

        if (timesheets.length === 0) {
            return '0h';
        }

        const work_time = timesheets.reduce((acc, { work_time }) => acc + work_time, 0);

        return convertNumToTime(work_time, { separator: 'h' });
    });

    addFilter('repository_index_task_column_sprint_content', (_, item) => {
        const { sprint } = item;

        if (!sprint) {
            return 'N/D';
        }

        return sprint.name;
    });

    // TaskTime Table

    addFilter('repository_index_timesheet_column_avatar_content', (_, item) => {
        if (!item.task || item.task === undefined) {
            return (
                <Avatar src={defaultAvatar} />
            );
        }

        return (
            <PopoverAvatar users={item.task.users} />
        );
    });

    // Agnostic

    addFilter('repository_index_task_column_due_date_content', (_, item) => {
        const { due_date: dueDate } = item;

        if (!dueDate) {
            return 'N/D';
        }

        return dueDate.format('DD/MM/YYYY');
    });

    addFilter('repository_index_task_column_project.name_content', (_, item) => {
        return (
            <TaskTag 
                task={item} 
                showTags={{ type: false, status: false, priority: false }} 
                centered
            />
        );
    });

    /* * */

    addFilter('repository_index_task_sprinttable_columns', (columns) => {
        return columns;
    });

    addFilter('repository_index_task_timetable_columns', (columns) => {
        return columns;
    });

    addFilter('repository_index_task_table_columns', (columns) => {
        if (auth().user().currentRole('admin')) {
            return columns.filter(({ key }) => ![  ].includes(key));
        }
        if (auth().user().currentRole('developer')) {
            return columns.filter(({ key }) => ![  ].includes(key));
        }
        return columns;
    });

    /* KANBAN CARDS */

    addFilter('kanban_task_card_due_date_content', (_, item) => {

        const { t } = useTranslation();
        
        const { status, due_date: dueDate, recurring, recurring_type } = item;

        if (recurring == 1) {
            return (
                <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Icon 
                        name="schedule" 
                        sx={{ width: 13.33, height: 13.33, color: '#7F6F6F' }}
                    />
                    <Box
                        sx={{
                            px: '4px',
                            py: '2px',
                            borderRadius: '4px',
                            backgroundColor: '#F5F5F5',
                        }}
                    >
                        <Typography 
                            sx={{
                                fontWeight: 500, 
                                fontSize: 12,
                                color: '#191919',
                                opacity: '0.56'
                            }}
                        >
                            {t(`recurring_type.${recurring_type}`)}
                        </Typography>
                    </Box>
                </Stack>
            );
        }

        if (!dueDate) {
            return (
                <Box
                    sx={{
                        px: '4px',
                        py: '2px',
                        borderRadius: '4px',
                        backgroundColor: '#F5F5F5',
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 500, 
                            fontSize: 12,
                            color: '#191919',
                            opacity: '0.56'
                        }}
                    >
                        Sem data
                    </Typography>
                </Box>
            );
        }

        const weekDay = isWithinDaysAfter(dueDate);
        const weekDayBefore = isWithinDaysBefore(dueDate);

        const isLate = dueDate.isBefore(dayjs(), 'day') && ![40, 50].includes(status);

        return (
            <Box
                sx={{
                    px: '4px',
                    py: '2px',
                    borderRadius: '4px',
                    backgroundColor: isLate ? '#FFDFDF' : '#F5F5F5',
                }}
            >
                <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Icon 
                        name="schedule" 
                        sx={{ width: 13.33, height: 13.33, color: isLate ? '#FF0000' : '#191919' }}
                    />
                    <Typography sx={{
                        fontWeight: 500, 
                        fontSize: 12,
                        color: isLate ? '#FF0000' : '#191919',
                        opacity: isLate ? '1' : '0.56'
                    }}
                    >
                        {!isLate
                            ? weekDay
                            : weekDayBefore
                        }
                    </Typography>
                </Stack>
            </Box>
        );
    });

};

