import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const PhotoFilterIcon = (props) => {
    return (
        <SvgIcon {...props} >
            <svg width="24" height="29" viewBox="0 0 24 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 11.3019L13.2087 14.9879L13.3216 15.3321H13.6839L17.5825 15.3321L14.4324 17.5998L14.1354 17.8136L14.2494 18.1614L15.4551 21.8381L12.2921 19.5611L12 19.3508L11.7079 19.5611L8.54487 21.8381L9.75057 18.1614L9.86461 17.8136L9.56759 17.5998L6.41745 15.3321L10.3161 15.3321H10.6784L10.7913 14.9879L12 11.3019Z" stroke="currentColor"/>
                <g opacity="0.72">
                    <path d="M17 4.74316H19.5C20.163 4.74316 20.7989 5.00414 21.2678 5.46868C21.7366 5.93322 22 6.56327 22 7.22023V24.5597C22 25.2166 21.7366 25.8467 21.2678 26.3112C20.7989 26.7758 20.163 27.0367 19.5 27.0367H4.5C3.83696 27.0367 3.20107 26.7758 2.73223 26.3112C2.26339 25.8467 2 25.2166 2 24.5597V7.22023C2 6.56327 2.26339 5.93322 2.73223 5.46868C3.20107 5.00414 3.83696 4.74316 4.5 4.74316H7" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M15.75 2.2666H8.25C7.55964 2.2666 7 2.82111 7 3.50513V5.9822C7 6.66622 7.55964 7.22073 8.25 7.22073H15.75C16.4404 7.22073 17 6.66622 17 5.9822V3.50513C17 2.82111 16.4404 2.2666 15.75 2.2666Z" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
            </svg>
        </SvgIcon>
    );
};

export default PhotoFilterIcon;