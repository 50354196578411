import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const CopyIcon = (props) => {
    return (
        <SvgIcon {...props} >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <path d="M21.99 4.65234C21.99 3.55234 21.1 2.65234 20 2.65234H4C2.9 2.65234 2 3.55234 2 4.65234V16.6523C2 17.7523 2.9 18.6523 4 18.6523H18L22 22.6523L21.99 4.65234ZM20 4.65234V17.8223L18.83 16.6523H4V4.65234H20ZM6 12.6523H18V14.6523H6V12.6523ZM6 9.65234H18V11.6523H6V9.65234ZM6 6.65234H18V8.65234H6V6.65234Z" fill="currentColor"/>
            </svg>
        </SvgIcon>
    );
};

export default CopyIcon;