
import React from 'react';

import { styled } from '@mui/material/styles';
import MainAvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';

import defaultAvatar from '../../../images/avatar.jpeg';


const AvatarGroup = styled(MainAvatarGroup)(({ theme, defaultStyle: sx }) => ({
    '& .MuiAvatarGroup-avatar': {
        // marginBottom: 0,
        marginTop: 2,
    },
    // '& .MuiAvatar-root': {
    //     // marginRight: theme.spacing(1), // Adiciona uma margem à direita de cada avatar
    // },
    '& .MuiAvatar-colorDefault': {
        fontSize: 15,
        marginTop: 5,
        marginLeft: 1, // Adiciona uma margem à esquerda do "+2"
        ...sx,
    }
}));


const PopoverAvatar = ({ users, groupMax = 3, sx }) => {
    
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    const isAlone = !Array.isArray(users) || users.length === 1;

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const mountAvatar = (user) => {
        // console.log({ user });

        const avatar = user.files.avatar_src
            ? user.files.avatar_src
            : defaultAvatar;

        return (
            <Avatar
                key={user.id}
                alt={user.name}
                src={avatar}
                sx={{ 
                    m: 'auto', 
                    ...sx
                }}
                //
                aria-owns={open ? `${user.name}-mouse-over-popover` : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            />
        );
    };

    return (
        <>
            <AvatarGroup max={groupMax} defaultStyle={sx} >
                {users.map((user) => mountAvatar(user))}
            </AvatarGroup>

            <Popover
                id={`${isAlone ? users[0].name : 'avatarGroup'}-mouse-over-popover`}
                sx={{ pointerEvents: 'none' }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography sx={{ p: 1 }}>
                    {isAlone
                        ? users[0].name
                        : users.map((user) => user.name).join(', ')
                    }
                </Typography>
            </Popover>
        </>
    );
};

export default PopoverAvatar;