
import React from 'react';

import { Icon } from '@arandu/laravel-mui-admin';

import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PlayIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Tooltip from '@mui/material/Tooltip';

import useTimeTrackerDialog from './Timer.hooks';

import { formatTime } from '../../support/date';


const TimerBox = styled(Stack)(() => ({
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}));

const TimerTitle = styled(Typography)(({ small = false }) => ({
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: small ? 24 : 96,
}));

const SmallBar = styled(Stack)(({ small = false }) => ({
    flexDirection: 'row',
    width: small ? '100%' : 'unset',
}));

const BaseButton = styled(Button)(({ small = false }) => ({
    minWidth: 'unset',
    width: small ? 32 : 130,
    height: small ? 32 : 40,
    ...(small && { padding: '7.5px' }),
    // color: small ? '#000' : 'auto',
    fontSize: small ? 18 : 20,
    fontWeight: '700',
    lineHeight: '24px',
    letterSpacing: '0.05em',
    textAlign: 'left',
    ...(small && { border: 'none' }),
    transition: 'all 0.05s',
}));

const ActionButton = styled(BaseButton)(({ small = false }) => ({
    width: small ? 64 : 260,
    ...(small && { padding: '4px 15.5px' }),
}));

const PlayButton = styled(ActionButton)(({ }) => ({
    width: '100%',
    marginBottom: 12,
    color: '#fff',
}));


const Timer = ({ 
    onTimeTrackerClick = () => null,
    onTimerFinished = () => null,
    small 
}) => {

    const {
        timeElapsed,
        isRunning,
        onPlay,
        onPause,
        onFinish
    } = useTimeTrackerDialog();

    const handleFinish = () => {
        onFinish().then(({ endDate, startDate }) => {            
            onTimerFinished(startDate, endDate);
        });
    };

    const playLabel = timeElapsed === 0 ? 'Iniciar' : 'Resumir';
  
    return (
        <TimerBox>
            <TimerTitle small={small} >
                {formatTime(timeElapsed)}
            </TimerTitle>

            <Stack
                direction="row"
                justifyContent="center"
                sx={{ 
                    width: small ? '100%' : 'unset',
                    mt: small ? 1 : 4 
                }}
                {...!small && { spacing: 2 }}
            >
                {small
                    ? (
                        <Stack 
                            direction="column" 
                            sx={{ width: '100%' }}
                        >
                            <PlayButton
                                variant="contained"
                                color="primary"
                                onClick={() => onPlay()}
                                small
                            >
                                {timeElapsed === 0 ? 'INICIAR' : 'REINICIAR'}
                            </PlayButton>

                            <SmallBar 
                                justifyContent="space-between"
                                gap={1.5} 
                                small
                            >
                                <Tooltip title="Expandir contador">
                                    <BaseButton
                                        variant="outlined"
                                        color="secondary"
                                        onClick={onTimeTrackerClick}
                                        small
                                    >
                                        <Icon name="expand" />
                                    </BaseButton>
                                </Tooltip>

                                <Tooltip title={isRunning ? 'Pausar' : playLabel}>
                                    <BaseButton
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => isRunning 
                                            ? onPause(timeElapsed) 
                                            : onPlay(timeElapsed)
                                        }
                                        small
                                    >
                                        {isRunning 
                                            ? <PauseIcon fontSize="medium" />
                                            : <PlayIcon fontSize="medium" />
                                        }
                                    </BaseButton>
                                </Tooltip>

                                <ActionButton
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleFinish}
                                    disabled={!isRunning && timeElapsed === 0}
                                    small
                                >
                                    FIM
                                </ActionButton>
                            </SmallBar>
                        </Stack>
                    )
                    : (
                        <>
                            <ActionButton
                                variant="outlined"
                                color="secondary"
                                onClick={() => onPlay()}
                            >
                                {timeElapsed === 0 ? 'INICIAR' : 'REINICIAR'}
                            </ActionButton>

                            <Tooltip title={isRunning ? 'Pausar' : playLabel}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => isRunning 
                                        ? onPause(timeElapsed) 
                                        : onPlay(timeElapsed)
                                    }
                                >
                                    {isRunning 
                                        ? <PauseIcon fontSize="medium" />
                                        : <PlayIcon fontSize="medium" />
                                    }
                                </Button>
                            </Tooltip>

                            <ActionButton
                                variant="contained"
                                color="secondary"
                                onClick={handleFinish}
                                disabled={!isRunning && timeElapsed === 0}
                            >
                                FINALIZAR
                            </ActionButton>
                        </>
                    )
                }
            </Stack>
        </TimerBox>
    );
};

export default Timer;