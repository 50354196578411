
import { config } from '@arandu/laravel-mui-admin';


export const colors = (constant) => {

    const taskTypes = config('boot.data.constants.taskTypes');
    const type = taskTypes[constant];

    switch (type) {
    case 'Épico':
        return {
            color: '#42774A',
            background: '#E6F2E7'
        };
    case 'Tarefa':
        return {
            color: '#FA510C',
            background: '#FFE8D5'
        };
    case 'Sub Tarefa':
        return {
            color: '#1D9798',
            background: '#E9F0F1'
        };
    case 'Bug': 
        return {
            color: '#A626D3',
            background: '#F8E8FF'
        };
    default:
        return {
            color: '#191919',
            background: '#a7a7a7',
        };
    }
};

export const icon = (constant) => {

    const taskTypes = config('boot.data.constants.taskTypes');
    const type = taskTypes[constant];

    switch (type) {
    case 'Épico':
        return 'epic';
    case 'Tarefa':
        return 'task';
    case 'Sub Tarefa':
        return 'subTask';
    case 'Bug':
        return 'bug';
    }
};