import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const InstagramIcon = (props) => {
    return (
        <SvgIcon {...props} >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M4.44444 0C1.99378 0 0 1.99378 0 4.44444V11.5556C0 14.0062 1.99378 16 4.44444 16H11.5556C14.0062 16 16 14.0062 16 11.5556V4.44444C16 1.99378 14.0062 0 11.5556 0H4.44444ZM4.44444 1.77778H11.5556C13.0258 1.77778 14.2222 2.97422 14.2222 4.44444V11.5556C14.2222 13.0258 13.0258 14.2222 11.5556 14.2222H4.44444C2.97422 14.2222 1.77778 13.0258 1.77778 11.5556V4.44444C1.77778 2.97422 2.97422 1.77778 4.44444 1.77778ZM12.4444 2.66667C12.2087 2.66667 11.9826 2.76032 11.8159 2.92702C11.6492 3.09372 11.5556 3.31981 11.5556 3.55556C11.5556 3.7913 11.6492 4.0174 11.8159 4.18409C11.9826 4.35079 12.2087 4.44444 12.4444 4.44444C12.6802 4.44444 12.9063 4.35079 13.073 4.18409C13.2397 4.0174 13.3333 3.7913 13.3333 3.55556C13.3333 3.31981 13.2397 3.09372 13.073 2.92702C12.9063 2.76032 12.6802 2.66667 12.4444 2.66667ZM8 3.55556C5.54933 3.55556 3.55556 5.54933 3.55556 8C3.55556 10.4507 5.54933 12.4444 8 12.4444C10.4507 12.4444 12.4444 10.4507 12.4444 8C12.4444 5.54933 10.4507 3.55556 8 3.55556ZM8 5.33333C9.47022 5.33333 10.6667 6.52978 10.6667 8C10.6667 9.47022 9.47022 10.6667 8 10.6667C6.52978 10.6667 5.33333 9.47022 5.33333 8C5.33333 6.52978 6.52978 5.33333 8 5.33333Z" fill="currentColor"/>
            </svg>
        </SvgIcon>
    );
};

export default InstagramIcon;