
import _ from 'lodash';


export const isRelation = (attributes, field) => !Object.keys(attributes).includes(field);

export const serializeRelations = (
    relations, 
    transformPayload = (payload) => payload
) => {
    const reduced = Object.keys(relations)
        .reduce((acc, key) => {
            if (relations[key]) {
                acc[key] = _.isArray(relations[key])
                    ? relations[key].map((relItem) => relItem instanceof File || relItem instanceof Blob
                        ? relItem
                        : {
                            id: relItem?.id,
                            ...(relItem.attributes
                                ? relItem?.attributes
                                : relItem
                            ),
                        })
                    : {
                        id: relations[key]?.id,
                        ...(relations[key].attributes
                            ? relations[key]?.attributes
                            : relations[key]
                        ),
                    };
            } else {
                acc[key] = null;
            }

            return acc;
        }, {});

    return transformPayload(reduced);
};

export const getFieldValue = (item, field) => {
    
    const { name, type } = field;
    
    const value = item[name];

    if (_.isObject(value)) {
        switch (type) {
        case 'autocomplete': {
            if (field.multiple) {
                return value.map((_item) => {
                    if (typeof _item.json === 'function') {
                        return _item.json();
                    }
                    
                    return _item;
                });
            }
            if (typeof value.json === 'function') {
                return value.json();
            }
            return value;
        }
        case 'date': return value.$d;
        case 'draft': return {
            html: value.html,
            plainText: value.plainText,
            rtf: value.rtf,
        };
        case 'dropzone': {
            if(Array.isArray(value)) {
                return value.map((_item) => {
                    if (typeof _item.json === 'function') {
                        return _item.json();
                    } 
                    return _item;
                });
            }
            break;
        }
        //
        default: return value;
        }
    }
    
    if (type === 'autocomplete' && !value) {
        return null;
    }

    return value ?? '';
};
