import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const GoogleCalendarIcon = (props) => {
    return (
        <SvgIcon {...props} >
            <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M44.8088 19.692L44.3183 15.75H34.434L41.1255 9.0585L37.6425 5.88375C33.48 2.09025 28.1025 0 22.5 0C10.0935 0 0
                10.0935 0 22.5C0 34.9065 10.0935 45 22.5 45C34.9065 45 45 34.9065 45 22.5C45 21.6225 44.9393 20.7315 44.8088 19.692ZM22.5
                40.5C12.5595 40.5 4.5 32.4405 4.5 22.5C4.5 12.5595 12.5595 4.5 22.5 4.5C27.171 4.5 31.41 6.29325 34.6095 9.20925L29.808
                14.0108C27.837 12.3143 25.3057 11.25 22.5 11.25C16.2878 11.25 11.25 16.2878 11.25 22.5C11.25 28.7122 16.2878 33.75 22.5
                33.75C27.1103 33.75 31.0635 30.969 32.8005 27H24.75V20.25H40.3447C40.437 20.988 40.5 21.7372 40.5 22.5C40.5 32.4405 32.4405
                40.5 22.5 40.5ZM20.25 16.1347V20.25V27V28.8653C17.631 27.936 15.75 25.434 15.75 22.5C15.75 19.566 17.631 17.064 20.25 16.1347Z"
                fill="#191919"/>
            </svg>
        </SvgIcon>
    );
};

export default GoogleCalendarIcon;