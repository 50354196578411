import React from 'react';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import RegisterTimeDialog from '../../RegisterTimeDialog/RegisterTimeDialog';
import TimeTrackerDialog from '../../TimeTrackerDialog/TimeTrackerDialog';
import TimeTrackerBox from '../../TimeTrackerDialog/TimeTrackerBox';

import ChangeActiveRoleForm from '../../ChangeActiveRoleForm';
import RecursiveList from '../../RecursiveList';

import useMenuDrawer from './Drawer.hooks';

import { 
    MenuDrawer, 
    MenuDrawerInner,
    WeekDisplay,
    AddHoursButton,
    MenuBackground,
} from './Drawer.layout';


const Drawer = ({
    DrawerComponent, DrawerHeader, blockUi,
    bottomMenuItems = [], drawerHeaderContent = null,
    drawerSidebarContent = null, handleDrawerClose,
    isTablet, theme, drawerWidth,
    setModelDialogOpen, modelDialogOpen,
    timeTrackerDialogOpen, handleOpenTimeTracker,
    handleTimeTrackerFinished,
}) => {

    const {
        menuItems,
        today,
        currentSprint,
    } = useMenuDrawer();

    return (
        <>
            <Box sx={{
                display: { xs: 'none', md: 'block' },
                width: drawerWidth,
                minWidth: drawerWidth,
            }} />
            
            <MenuBackground width={drawerWidth} sx={{height: '100%'}} />
            
            <MenuDrawer width={drawerWidth}>
                <DrawerHeader />

                <MenuDrawerInner elevation={2} >
                    <Stack>
                        <Typography>
                            <WeekDisplay sx={{ mb: 1, fontWeight: 500, opacity: '0.72' }} >
                                {`Semana ${currentSprint}`}
                            </WeekDisplay>
                            <WeekDisplay
                                sx={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: '#191919',
                                    opacity: '0.56'
                                }}
                            >
                                {`\u00A0- ${String(today.getDate()).padStart(2, '0')}/${String(today.getMonth() + 1).padStart(2, '0')}/${today.getFullYear()}`}
                            </WeekDisplay>
                        </Typography>

                        <ChangeActiveRoleForm />
                    </Stack>

                    <RecursiveList items={menuItems} />

                    {/* {!modelDialogOpen && !timeTrackerDialogOpen && (
                        <TimeTrackerBox 
                            onTimeTrackerClick={handleOpenTimeTracker}
                            onTimerFinished={handleTimeTrackerFinished}
                            small
                        />
                    )} */}

                    <Box>
                        <AddHoursButton
                            fullWidth
                            variant="contained"
                            onClick={() => setModelDialogOpen(true)}
                            sx={{ mt: 2 }}
                        >
                        Cadastrar Hora
                        </AddHoursButton>
                    </Box>
                </MenuDrawerInner>
            </MenuDrawer>
        </>
        
    // <DrawerComponent
    //     variant="permanent"
    //     PaperProps={{
    //         sx: { width: drawerWidth },
    //     }}
    //     sx={{
    //         display: { xs: 'none', md: 'block' },
    //     }}

    // >
    //     <DrawerHeader />
    //     {}
    // </DrawerComponent>
    );
};


export default Drawer;

