
import React from 'react';
import { useSearchParams } from 'react-router-dom';


export default function useTimeTrackerDialog() {

    const [searchParams] = useSearchParams(new URLSearchParams(document.location.search));

    const storedTargetDate = localStorage.getItem('targetDate');
    const initialTargetDate = storedTargetDate ? new Date(storedTargetDate) : null;
    
    const storedElapsedTime = localStorage.getItem('elapsedTime');
    const initialElapsedTime = storedElapsedTime ? parseInt(storedElapsedTime) : 0;
  
    const [targetDate, setTargetDate] = React.useState(initialTargetDate);
    const [elapsedTime, setElapsedTime] = React.useState(initialElapsedTime);

    const [timeElapsed, setTimeElapsed] = React.useState(0);

    const isRunning = React.useMemo(
        () => targetDate !== null, 
        [targetDate]
    );

    React.useEffect(() => {
        if (isRunning) {
            if (searchParams.has('pauseTimeTracker') && Boolean(searchParams.get('pauseTimeTracker'))) {
                pauseTimer(timeElapsed);
            } else {
                startTimer(timeElapsed);
            }
        } else if (timeElapsed > 0) {
            startTimer(timeElapsed);
        }
    }, [searchParams]);
  
    React.useEffect(() => {
        localStorage.setItem('targetDate', targetDate ? targetDate.toISOString() : '');
        localStorage.setItem('elapsedTime', elapsedTime.toString());
    }, [targetDate, elapsedTime]);
  
    React.useEffect(() => {
        setTimeElapsed(elapsedTime);

        if (!isRunning) {
            return;
        }

        const intervalId = setInterval(() => {
            const currentTime = new Date().getTime();
            const newTime = elapsedTime + currentTime - targetDate.getTime();
            setTimeElapsed(newTime);
        }, 1000);
    
        return () => clearInterval(intervalId);
    }, [targetDate]);

    const startTimer = (elapsedTime = 0) => {
        setTargetDate(new Date());
        setElapsedTime(elapsedTime);
    };

    const pauseTimer = (elapsedTime) => {
        setTargetDate(null);
        setElapsedTime(elapsedTime);
    };

    const resetTimer = async () => {
        setTargetDate(null);
        setElapsedTime(0);

        const endDate = new Date();
        const startDate = new Date(endDate.getTime() - timeElapsed);

        return {
            endDate,
            startDate
        };
    };

    return {
        timeElapsed,
        isRunning,
        onPlay: startTimer,
        onPause: pauseTimer,
        onFinish: resetTimer
    };
}
