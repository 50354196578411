import { addFilter } from '@arandu/laravel-mui-admin';


export default () => {

    addFilter(
        'repository_index_get_item_actions',
        (actions, item) => {
            if (item.className !== 'invoice') {
                return actions;
            }
            return [
                ...actions,
                { name: 'viewNfse', label: 'Vizualizar NFS-e', },
            ];
        }
    );
};
