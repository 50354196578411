/* eslint-disable react/react-in-jsx-scope */

import { addFilter, config, auth } from '@arandu/laravel-mui-admin';

import { Typography } from '@mui/material';

export default () => {

    const planningStatuses = config('boot.data.constants.planningStatuses');

    addFilter(
        'repository_index_planning_actions',
        (items) => {
            if (auth().user().currentRole() === 'developer') {
                return [];
            }
            return items;
        }
    );

    addFilter('repository_index_planning_column_status_content', (value) => {
        return (
            <Typography color="primary" fontWeight="bold" >
                {planningStatuses[value]}
            </Typography>
        );
    });

    addFilter('repository_index_planning_column_description_content', (value) => {

        const reducedString = value
            ? (value?.substr(0, 47) + '...')
            : 'Sem descrição';

        return (
            <Typography color="primary" fontWeight="bold" >
                {reducedString}
            </Typography>
        );
    });

    addFilter('repository_index_get_item_actions', (actions, item) => {
        if (item.className !== 'planning') {
            return actions;
        }
        if (auth().user().currentRole() === 'developer') {
            return [];
        }
        return actions;
    });

};
