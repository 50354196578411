
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';


export const MenuDrawer = styled(Box)(({ theme, width }) => ({
    position: 'absolute',
    display: { xs: 'none', md: 'flex' },
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: width,
    minWidth: width,
    py: 4,
    px: 2,
}));

export const MenuBackground = styled(Box)(({ width }) => ({
    backgroundColor: 'white',
    position: 'absolute',
    display: { xs: 'none', md: 'flex' },
    width: width,
    minWidth: width,
}));

export const MenuDrawerInner = styled(Paper)(({ theme }) => ({
    width: '100%',
    height: '100%',
    padding: '32px 16px',
    borderRadius: 0,
    boxShadow: 'none',
}));

export const WeekDisplay = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto',
    fontSize: 16,
    color: 'black',
    display: 'inline-block',
}));

export const AddHoursButton = styled(Button)(({ theme }) => ({
    minWidth: 70, 
    minHeight: 56,
    borderRadius: 5,
    textTransform: 'none', // Remove a transformação de texto padrão
    fontFamily: 'Roboto',
    border: 'none',
    boxShadow: 'none',
    backgroundColor: '#A5CDAA',
    color: '#191919',
    fontSize: '16px',
    fontWeight: 500,
    '&:hover': {
        backgroundColor: '#89b498',
        boxShadow: 'none',
    },
}));