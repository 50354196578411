import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const SprintIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg
                width="21"
                height="19"
                viewBox="0 0 21 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path 
                    fillRule="evenodd" 
                    clipRule="evenodd" 
                    d="M11.9562 0L7.05584 2.98527L12.0913 5.73647L12.0428 3.67376C12.1407 3.71067 12.2361 3.75494 12.3279 3.80631C12.5804 3.94756 12.8011 4.13942 12.976 4.36988C13.151 4.60034 13.2765 4.86444 13.3446 5.14563C13.4128 5.42683 13.4222 5.71908 13.3722 6.00407C13.3222 6.28906 13.2139 6.56066 13.0541 6.80186C12.8943 7.04306 12.6864 7.24867 12.4434 7.40581C12.2005 7.56295 11.9277 7.66824 11.6422 7.71509C11.3839 7.75746 11.1203 7.7512 10.8648 7.6969C10.3811 7.06936 9.77781 6.54018 9.08708 6.14151C8.16186 5.6075 7.11237 5.32646 6.0441 5.32663C4.97583 5.3268 3.92643 5.60819 3.00138 6.14249C2.60179 6.3733 2.23149 6.64778 1.8967 6.95972L0.034668 6.38431L1.3086 11.9792L5.51693 8.07847L3.49434 7.45344C3.55839 7.41217 3.62353 7.3724 3.6897 7.33418C4.40555 6.9207 5.21764 6.70295 6.04432 6.70282C6.87101 6.70269 7.68316 6.92017 8.39915 7.33342C9.11513 7.74667 9.70972 8.34111 10.1231 9.05699C10.5366 9.77288 10.7542 10.585 10.7543 11.4117C10.7544 12.2383 10.5368 13.0505 10.1235 13.7664C9.71021 14.4824 9.11572 15.0769 8.3998 15.4903C7.99473 15.7242 7.55885 15.8954 7.10697 16H6.0347V16.0182H0.531124V17.4906H6.04508V17.4975C6.14175 17.4975 6.23827 17.4952 6.33456 17.4906H6.58797V17.4732L6.62269 17.47H8.47343V17.4906H9.67337V17.47H10.3594V17.4906H11.5593V17.47H12.245V17.4906H13.4449V17.47H14.1304V17.4906H15.3303V17.47H15.9962V19L20.9655 16.4497L15.9962 13.8995V16H10.043C10.5475 15.5603 10.9781 15.0387 11.3154 14.4545C11.8494 13.5293 12.1306 12.4798 12.1305 11.4116C12.1304 10.5682 11.9551 9.7365 11.6184 8.96851C11.6934 8.96136 11.7684 8.95167 11.843 8.93942C12.2971 8.86492 12.7309 8.69748 13.1172 8.44759C13.5036 8.19769 13.8342 7.87072 14.0883 7.48715C14.3425 7.10358 14.5147 6.67167 14.5942 6.21846C14.6737 5.76526 14.6588 5.30052 14.5504 4.85334C14.442 4.40617 14.2425 3.98618 13.9643 3.61969C13.686 3.2532 13.3352 2.9481 12.9336 2.72349C12.6447 2.56193 12.3342 2.44452 12.0121 2.37449L11.9562 0Z"
                    fill="currentColor"
                />
            </svg>
        </SvgIcon>
    );
};

export default SprintIcon;
