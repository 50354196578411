
import { addFilter, auth } from '@arandu/laravel-mui-admin';

import Drawer from '../../components/Layout/DrawerMenu/Drawer';

export default () => {

    addFilter('side_menu_layout_drawer', (prev, props) => {
        if (auth().user().currentRole() === 'admin') {
            return prev;
        }
        return <Drawer {...props} />;
    });

    addFilter('side_menu_layout_menu_button', (prev, props) => {
        if (auth().user().currentRole() === 'admin') {
            return prev;
        }
        return null;
    });

};

