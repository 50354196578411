import React from 'react';

import { auth, config, dialog } from '@arandu/laravel-mui-admin';

import { initializeApp } from 'firebase/app';
// Import the functions you need from the SDKs you need
    
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import Dispatcher from '../support/dispatcher';

const DEFAULT_VALUE = {
    firebaseApp: null,
};

export const FirebaseContext = React.createContext(DEFAULT_VALUE);

const dispatcher = new Dispatcher();


const FirebaseProvider = ({ children }) => {

    const [firebaseApp, setFirebaseApp] = React.useState(DEFAULT_VALUE.firebaseApp);

    React.useEffect(() => {
        if (['Notification' in window, !!config('boot.data.firebase'), !!config('boot.data.user')].includes(false)) {
            return;
        }
        console.log('running firebase provider...');

        const firebaseConfig = config('boot.data.firebase');

        // Initialize Firebase
        const app = initializeApp(firebaseConfig);

        const dispatcher = new Dispatcher();

        setFirebaseApp(app);

        const messaging = getMessaging(app);

        const user = auth().user();

        const fetchTokenAndWriteToUser = () => {
            console.log('getting FCM token...');

            getToken(messaging, { vapidKey: config('boot.data.vapidKey') })
                .then((token) => {
                    // console.log('Got FCM token:', token);
            
                    if (!(user.fcm_token || []).includes(token)) {
                        user.fcm_token = [
                            ...(user.fcm_token || []),
                            token
                        ];

                        if (!user.preferences.notifications.channels.includes('NotificationChannels\\Fcm\\FcmChannel')) {
                            user.preferences.notifications.channels.push(
                                'NotificationChannels\\Fcm\\FcmChannel'
                            );
                        }

                        user.save({
                            sendsOnlyModifiedFields: false,
                        });
                    }                    
                })
                .catch((err) => {
                    console.error('An error occurred while retrieving token. ', err);
                });
        };

        const requestPermission = async () => {
            console.log('Requesting permission...');
            const permission = await Notification.requestPermission();
            
            if (permission === 'granted') {
                console.log('Notification permission granted.');
                fetchTokenAndWriteToUser();
            }                
        };

        console.log('Notification permission', Notification.permission);
        if (Notification.permission !== 'granted') {
            setTimeout(() => {
                dialog.confirm('Para o sistema funcionar corretamente, é necessário permitir as notificações. Deseja permitir?')
                    .then((result) => {
                        console.log('dialog result:', result);
                        if (result) {
                            requestPermission();
                        }
                    });
            }, 1000);
        } else {
            fetchTokenAndWriteToUser();
        }

        onMessage(messaging, (payload) => {
            console.log('[firebase-messaging-provider] Received front message!');

            // if (payload.data?.show === '1') {
            //     const temp = new Notification(payload.notification.title, {
            //         body: payload.notification.body,
            //         icon: payload.notification.image,
            //     });

            //     console.log('notification sent (front):', temp);
            // } else if (payload.data?.event) {
            //     // eslint-disable-next-line no-unused-vars
            //     const { show, event, ...data } = payload.data;

            //     dispatcher.dispatch(event, data);
            // }

            if (payload.data?.show !== '1' && payload.data?.event) {
                // eslint-disable-next-line no-unused-vars
                const { show, event, ...data } = payload.data;

                dispatcher.dispatch(event, data);
            }

            console.log('notification sent (front):');
        });
            
        
    }, []);

    return (
        <FirebaseContext.Provider value={{ firebaseApp, dispatcher }}  >
            {children}
        </FirebaseContext.Provider>
    );
};


export default FirebaseProvider;
