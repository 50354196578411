import { removeAction, addAction, route, toast } from '@arandu/laravel-mui-admin';

import actions from '@arandu/laravel-mui-admin/lib/macros/repositoryIndex/actions';
import axios from 'axios';

export default () => {
    removeAction(
        'repository_index_click_item',
        actions.clickItem,
    );
    removeAction(
        'repository_index_new_item',
        actions.newItem,
    );

    addAction('repository_index_click_item', (...params) => {
        const [item, { navigate }] = params;

        // if (item.className === 'post') {
        //     navigate(route('admin.post.edit', { id: item.id }));
        //     return;
        // }

        if (item.className === 'composition') {
            navigate(route('admin.composition.edit', { id: item.id }));
            return;
        }

        if (item.className === 'planning') {
            navigate(route('admin.planning.edit', { id: item.id }));
            return;
        }

        if (item.className === 'input') {
            navigate(route('admin.input.edit', { id: item.id }));
            return;
        }

        if (item.className === 'budget') {
            navigate(route('admin.budget.edit', { id: item.id }));
            return;
        }

        if (item.className === 'form') {
            navigate(route('admin.form.edit', { id: item.id }));
            return;
        }

        actions.clickItem(...params);
    });

    addAction('repository_index_new_item', (...params) => {
        const [className, { navigate }] = params;

        // if (className === 'post') {
        //     navigate(route('admin.post.new'));
        //     return;
        // }

        if (className === 'composition') {
            navigate(route('admin.composition.new'));
            return;
        }

        if (className === 'planning') {
            navigate(route('admin.planning.new'));
            return;
        }

        if (className === 'input') {
            navigate(route('admin.input.new'));
            return;
        }

        if (className === 'budget') {
            navigate(route('admin.budget.new'));
            return;
        }

        if (className === 'form') {
            navigate(route('admin.form.new'));
            return;
        }

        actions.newItem(...params);
    });

    addAction(
        'repository_index_item_action_createProposal',
        (item) => {
            axios({
                method: 'POST',
                url: route('admin.budget.createProposalInDrive', {id: item.id}),
            }).then((response) => {
                console.log(response);
                if (response.status === 200) {
                    toast.success('Proposta criada no Google Drive!');
                } else {
                    toast.error('A Proposta não foi criada!');
                }
            });

            return;
        }
    );

    addAction(
        'repository_index_item_action_generateContract',
        (item) => {
            axios({
                method: 'POST',
                url: route('admin.budget.createContractInDrive', {id: item.id}),
            }).then((response) => {
                console.log(response);
                if (response.status === 200) {
                    toast.success('Contrato criado no Google Drive!');
                } else {
                    toast.error('O Contrato não foi criado!');
                }
            });
            
            return;
        }
    );

    addAction(
        'repository_index_item_action_approvedProposal',
        (item) => {
            axios({
                method: 'POST',
                url: route('admin.budget.update', {id: item.id}),
                data: {
                    status: 3,
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }).then((response) => {
                console.log(response);
                if (response.status === 200) {
                    toast.success('Orçamento aprovado!');
                } else {
                    toast.error('Não foi possível realizar esta ação!');
                }
            });
            
            return;
        }
    );

    addAction(
        'repository_index_item_action_rejectedProposal',
        (item) => {
            axios({
                method: 'POST',
                url: route('admin.budget.update', {id: item.id}),
                data: {
                    status: 4,
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }).then((response) => {
                console.log(response);
                if (response.status === 200) {
                    toast.success('Orçamento Rejeitado!');
                } else {
                    toast.error('Não foi possível realizar esta ação!');
                }
            });
            
            return;
        }
    );

    addAction(
        'repository_index_item_action_createProject',
        (item) => {
            axios({
                method: 'POST',
                url: route('admin.contract.createProject', {id: item.id}),
            }).then((response) => {
                console.log(response);
                if (response.status === 200) {
                    toast.success('O Projeto foi criado!');
                } else {
                    toast.error('Não foi possível realizar esta ação!');
                }
            });
            
            return;
        }
    );

    addAction(
        'repository_index_item_action_viewNfse',
        (item) => {
            axios({
                method: 'GET',
                url: route('admin.invoice.redirectNfse', {id: item.id}),
            }).then((response) => {
                console.log(response);
                if (response.status === 200) {
                    toast.success('A Nota Fiscal está sendo exibida em outra aba!');
                    window.open(response.data.link, '_blank');
                } else {
                    toast.error('Não foi possível realizar esta ação!');
                }
            });
            
            return;
        }
    );

    addAction(
        'repository_index_item_action_viewProposal',
        (item) => {
            if (item.proposal_url) {
                window.open(item.proposal_url, '_blank');
                toast.success('A Proposta está sendo exibida em outra aba!');
            } else {
                toast.error('Não foi possível realizar esta ação!');
            }

            return;
        }
    );

    addAction(
        'repository_index_item_action_viewContract',
        (item) => {
            axios({
                method: 'GET',
                url: route('admin.budget.item', {id: item.id}),
            }).then((response) => {
                
                if (response.status === 200 && response.data.contract !== null) {
                    toast.success('O Contrato está sendo exibido em outra aba!');
                    window.open(response.data.contract.contract_drive_url, '_blank');           
                } else {
                    toast.error('Não foi possível realizar esta ação!');
                }
            });

            return;
        }
    );
};