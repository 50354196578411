/* eslint-disable max-len */
/* eslint-disable i18next/no-literal-string */


const PRIMARY = '#1D9798';
const SECONDARY = '#FA510C';
const SUCCESS = '#076F18';
const ERROR = '#eb4432';
const WARNING = '#f49d37';
const INFO = '#8338ec';

const WHITE = '#FFFFFF';
const BLACK = '#191919';
const BlUE = '#0288D1';
const MOSS = '#748276';
const RED = '#F60C0C';

const LIGHT_GREEN = '#A5CDAA';
const LIGHT_ORANGE = '#EBA564';
const LIGHT_RED = '#E45F5F';
const LIGHT_YELLOW = '#F1E684';

const TEXT = '#565454';
const LIGHT = '#E9F0F1';
const DARK = '#171717';

const PALETTE = {
    primary: PRIMARY,
    secondary: SECONDARY,
    success: SUCCESS,
    error: ERROR,
    warning: WARNING,
    info: INFO,
    //
    black: BLACK,
    blue: BlUE,
    moss: MOSS,
    red: RED,
    //
    'light-green': LIGHT_GREEN,
    'light-orange': LIGHT_ORANGE,
    'light-red': LIGHT_RED,
    'light-yellow': LIGHT_YELLOW,
};

/**
 * As configurações do tema do Material UI.
 */
export default {
    /**
     * Sobreescreva o tema padrão do material UI aqui.
     *
     * Crie variantes de componentes, personalize as fontes e muito mais.
     *
     * Https://mui.com/material-ui/customization/default-theme/
     * https://mui.com/material-ui/customization/theme-components/.
     *
     * Https://m2.material.io/inline-tools/color/
     * https://zenoo.github.io/mui-theme-creator/.
     */

    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
                    //
                },
            },
            variants: [
                {
                    props: { variant: 'table' },
                    style: {
                        borderRadius: 8,
                        //
                    },
                },
            ],
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    '&.drawer-formField': {
                        paddingBottom: 14,
                        marginTop: -1, 
                        fontWeight: 400, 
                        fontSize: 18, 
                        lineHeight: '13px',
                        letterSpacing: '0.15000000596046448px',
                        textAlign: 'left',
                        transform: 'none !important', 
                    },
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    width: 40,
                    height: 40,
                    backgroundColor: '#afafaf',
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                // root: {
                //     borderRadius: 10,
                //     height: 34,
                //     fontSize: 14,
                //     fontWeight: 500,
                //     letterSpacing: 0.16,
                //     color: '#191919',
                //     backgroundColor: '#D5EFFD',
                //     '&.MuiChip-colorPrimary': {
                //         backgroundColor: '#1D9798',
                //         color: 'white',
                //     },
                //     '&.MuiChip-colorSecondary': {
                //         backgroundColor: '#FA510C',
                //         color: 'white',
                //     },
                //     '.MuiChip-avatar': {
                //         backgroundColor: 'blue',
                //     }
                // },
                root: {
                    minWidth: 34,
                    width: 'fit-content',
                    height: 34,
                    fontFamily: 'Roboto',
                    fontSize: '20px',
                    fontWeight: '500',
                    lineHeight: '18px',
                    letterSpacing: '0.1599999964237213px',
                    textAlign: 'center',
                    borderRadius: 10,
                    transition: 'all .3s ease',
                    cursor: 'pointer',
                    '& span': { padding: '6px' },
                },
            },
            variants: [
                {
                    props: { small: true },
                    style: {
                        minWidth: 35.275, // 65, 136,
                        height: 'unset',
                        fontFamily: 'Roboto',
                        fontSize: '18px',
                        fontWeight: '400',
                        lineHeight: '24px',
                        letterSpacing: '0.15000000596046448px',
                        textAlign: 'left',
                        borderRadius: 5,
                        '& span': { 
                            padding: '4.25px 12.5px',
                        },
                    }
                },
                {
                    props: { rounded: true },
                    style: {
                        display: 'flex',
                        justifyContent: 'start',
                        minWidth: 131,
                        height: '40px',
                        padding: '8px',
                        color: '#000',
                        fontFamily: 'Roboto',
                        fontSize: '18px',
                        fontWeight: '400',
                        borderRadius: '100px',
                        backgroundColor: '#e3ebecaa', // '#E9F0F1',
                        transition: 'all .3s ease',
                        '& span': { padding: '6px' },
                        '&:hover': {
                            // marginLeft: 4,
                            // marginRight: 4,
                            color: '#191919',
                            backgroundColor: 'transparent',
                            border: '2px solid #d6dedf',
                            boxSizing: 'border-box',
                            transform: 'scale(1.075)',
                        },
                    },
                }
            ],
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    '&.rowsPerPage': {
                        width: 66,
                        height: 32,
                        marginLeft: 8,
                        color: '#1D9798',
                    },
                },
            },
            variants: [
                {
                    props: { variant: 'filled' },
                    style: {
                        backgroundColor: '#E9F0F1',
                        '& .MuiSelect-select': {
                            fontFamily: 'Roboto',
                            fontSize: 18,
                            fontWeight: 'bold',
                            color: '#191919',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                        },
                    },
                },
            ],
        },
        MuiBottomNavigation: {
            styleOverrides: {
                root: {
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1000,
                    backgroundColor: LIGHT,
                }
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    fontWeight: 700,
                    fontSize: 14,
                    borderRadius: 5,
                    transition: 'all .25s ease',
                    '& .MuiButtonBase-root': {
                        minHeight: 40,
                        padding: '5px 15px',
                        '& .MuiAccordionSummary-content': {
                            margin: 0,
                            '& .MuiStack-root': {
                                direction: 'row',
                                alignItems: 'center',
                                padding: 0,
                            },
                        }
                    },
                },
            },
            variants: [
                {
                    props: { variant: 'contained' },
                    style: {
                        border: `3px solid ${DARK}`,
                    },
                },
                {
                    props: { variant: 'outlined' },
                    style: {
                        color: DARK,
                        backgroundColor: LIGHT,
                        border: `3px solid ${DARK}`,
                        '&:hover': {
                            border: `3px solid ${DARK}`,
                        },
                    },
                },

                ...Object.keys(PALETTE).map((color) => ({
                    props: { variant: 'contained', color },
                    style: ({ theme }) => ({
                        backgroundColor: theme.palette[color].main,
                        boxShadow: `2px 2px 0px 0px ${[
                            'primary', 
                            'light-green'
                        ].includes(color)
                            ? theme.palette.secondary.main
                            : theme.palette.primary.main}`,
                        '&:hover': {
                            boxShadow: `2px 2px 0px 0px ${[
                                'primary', 
                            ].includes(color)
                                ? theme.palette.secondary.main
                                : theme.palette.primary.main}`,
                        },
                        '&:active': {
                            boxShadow: `0px 0px 0px 0px ${[
                                'primary', 
                            ].includes(color)
                                ? theme.palette.secondary.main
                                : theme.palette.primary.main}`,
                        },
                    }),
                })),

                ...Object.keys(PALETTE).map((color) => ({
                    props: { variant: 'outlined', color },
                    style: ({ theme }) => ({
                        boxShadow: `2px 2px 0px 0px ${theme.palette[color].main}`,
                        '&:active': {
                            boxShadow: `0px 0px 0px 0px ${theme.palette[color].main}`,
                        },
                    }),
                })),
            ],
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 'bold',
                    borderRadius: 5,
                    transition: 'all .25s ease',
                },
                contained: {
                    border: `3px solid ${DARK}`,
                },
                outlined: {
                    color: DARK,
                    backgroundColor: LIGHT,
                    border: `3px solid ${DARK}`,
                    '&:hover': {
                        border: `3px solid ${DARK}`,
                    },
                },
                sizeLarge: {
                    fontSize: 20,
                },
            },
            variants: [

                ...Object.keys(PALETTE).map((color) => ({
                    props: { variant: 'contained', color },
                    style: ({ theme }) => ({
                        boxShadow: `2px 2px 0px 0px ${[
                            'primary', 
                            'light-green'
                        ].includes(color)
                            ? theme.palette.secondary.main
                            : theme.palette.primary.main}`,
                        '&:hover': {
                            boxShadow: `2px 2px 0px 0px ${[
                                'primary', 
                            ].includes(color)
                                ? theme.palette.secondary.main
                                : theme.palette.primary.main}`,
                        },
                        '&:active': {
                            boxShadow: `0px 0px 0px 0px ${[
                                'primary', 
                            ].includes(color)
                                ? theme.palette.secondary.main
                                : theme.palette.primary.main}`,
                        },
                    }),
                })),

                ...Object.keys(PALETTE).map((color) => ({
                    props: { variant: 'outlined', color },
                    style: ({ theme }) => ({
                        boxShadow: `2px 2px 0px 0px ${theme.palette[color].main}`,
                        '&:active': {
                            boxShadow: `0px 0px 0px 0px ${theme.palette[color].main}`,
                        },
                    }),
                })),

                ...Object.keys(PALETTE).map((color) => ({
                    props: { variant: 'green', color },
                    style: ({ theme }) => ({
                        backgroundColor: theme.palette[color].main,
                        color: '#FFFFFF',
                        borderRadius: '8px',
                        textTransform: 'none',
                        fontSize: '16px',
                        fontWeight: '600'
                    }),
                })),
            ],
        },
        // MuiIconButton: {
        //     styleOverrides: {
        //         root: {
        //             borderRadius: '6px',
        //             maxWidth: '35px',
        //             maxHeight: '35px',
        //             backgroundColor: '#E9F0F1',
        //         },
        //     },
        //     variants: [
        //         {
        //             props: { variant: 'delete' },
        //             style: {
        //                 backgroundColor: '#F60C0C',
        //             },
        //         },
        //         {
        //             props: { variant: 'search' },
        //             style: {
        //                 backgroundColor: '#FA510C',
        //             },
        //         },
        //     ],
        // },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '&.checkDrawer': {
                        width: '40px !important',
                        '&:hover': {
                            backgroundColor: 'transparent !important',
                        },
                    },
                },
            }
        },
        MuiSwitch: {
            styleOverrides: {
                track: {
                    backgroundColor: '#C3C3C3',
                    height: '20px',
                    borderRadius: '10px',
                },
                switchBase: {
                    color: '#C3C3C3',
                    '&.Mui-checked': {
                        color: '#076F18',
                    },
                    '&.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: '#076F18',
                    },
                    '& .MuiSwitch-thumb': {
                        backgroundColor: '#FFFFFF',
                        width: '20px',
                        height: '20px',
                        marginTop: '3px',
                        marginBottom: '3px',
                    },
                },
            },
        },
        MuiBadge: {
            styleOverrides: {
                root: {
                    borderRadius: '6px',
                    maxWidth: '35px',
                    maxHeight: '35px',
                    backgroundColor: '#E9F0F1',
                },
                badge: {
                    borderRadius: '100%',
                    backgroundColor: '#F60C0C',
                    color: '#FFFFFF',
                    padding: '6.5px',
                },
            },
        },
        MuiPopper: {
            styleOverrides: {
                root: {
                    // width: 'fit-content',
                },
            },
        },
        MuiTable: {
            variants: [
                {
                    props: { variant: 'subtask' },
                    style: {
                        '& thead': {
                            backgroundColor: '#E9F0F1',
                        },
                    },
                },
            ],
        },
        MuiTableCell: {
            variants: [
                {
                    props: { variant: 'subtask' },
                    style: {
                        fontFamily: 'Roboto',
                        fontSize: '18px',
                        fontWeight: 'bold',
                    },
                },
            ],
        },
        // MuiMenuItem: { styleOverrides: { root: { '&.active': { color: '#EB4432' } } } },
        // MuiListItemButton: { styleOverrides: { root: { '&.active': { color: '#1D9798' } } } },
    },

    palette: {
        mode: 'light',
        text: { primary: TEXT },
        background: { 
            default: LIGHT,
        },

        ...Object.keys(PALETTE).reduce((acc, key) => {
            return { ...acc, [key]: { main: PALETTE[key] } };
        }, {}),

    },
};

