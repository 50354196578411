
import React from 'react';
import { useTranslation } from 'react-i18next';

import axios from 'axios';

import { route, toast } from '@arandu/laravel-mui-admin';

import useMenuItems from '../../../hooks/useMenuItems';


export default function useMenuDrawer() {

    const { t } = useTranslation();

    const menuItems = useMenuItems();

    const [currentSprint, setCurrentSprint] = React.useState('');

    const today = new Date();

    React.useEffect(() => {
        const getCurrentSprint = async () => {
            const payload = {
                method: 'GET',
                url: route('admin.sprint.current'),
            };
        
            const response = await axios(payload);
        
            if (response.status === 200) {
                setCurrentSprint(response.data.week_number);
            } else {
                toast.error(response?.data.message || t('task.error'));
            }
        };
        getCurrentSprint();
    }, []);

    return {
        menuItems,
        today,
        currentSprint,
    };
}
