import React from 'react';

import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { RecursiveMenu, Icon } from '@arandu/laravel-mui-admin';

const RecursiveList = ({
    items, onClick, 
    isSubmenu = false,
    collapsed = false, 
    ...props
}) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dropItems, setDropItems] = React.useState(null);
    const [childrenOpen, setChildrenOpen] = React.useState({});

    return (
        <List {...props}>
            {items
                .filter(({ hidden = () => false }) => !hidden())
                .map((item, index) => {
                    const {
                        text, icon, ListItemButtonProps = {}, element = null,
                        key, ListItemProps = {}, children,
                    } = item;

                    if (element) {
                        return element;
                    }

                    const { onClick: onClickItem, ...buttonProps } = ListItemButtonProps;

                    return (
                        <ListItem
                            key={key}
                            dense={isSubmenu}
                            disablePadding
                            sx={{ display: 'block' }}
                            {...ListItemProps}
                        >
                            {index == 0 && !isSubmenu && (
                                <Box
                                    component={'div'}
                                    sx={{
                                        my: 1,
                                        borderBottom: '1.5px solid #A5CDAA',
                                    }}
                                />
                            )}
                            <ListItemButton
                                sx={{
                                    ...{
                                        minHeight: 45,
                                        justifyContent: !collapsed ? 'initial' : 'center',
                                        px: '7px',
                                        borderRadius: '5px',
                                    },
                                }}
                                onClick={(e) => {
                                    if (onClickItem) {
                                        onClickItem(e);
                                    }
                                    if (children) {
                                        if (collapsed) {
                                            setDropItems(() => {
                                                setAnchorEl(e.currentTarget);
                                                return children;
                                            });
                                            return;
                                        }
                                        setChildrenOpen({
                                            ...childrenOpen,
                                            [key]: !childrenOpen[key],
                                        });
                                        return;
                                    }
                                    if (onClick) {
                                        onClick(e);
                                    }
                                }}
                                {...buttonProps}
                            >
                                {icon && !isSubmenu && (
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: !collapsed ? '16px' : 'unset',
                                            justifyContent: 'center',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            color: '#191919',
                                            opacity: 0.72,
                                        }}
                                    >
                                        <Icon name={icon} style={{color: '#191919'}} />
                                        {collapsed && (
                                            <Typography
                                                fontSize={10}
                                                sx={{ mt: 0.5, color: '#191919' }}
                                            >
                                                {text}
                                            </Typography>
                                        )}
                                    </ListItemIcon>
                                )}
                                {!collapsed
                                    && <ListItemText
                                        primary={text}
                                        primaryTypographyProps={{
                                            color: '#191919',
                                            fontWeight: 500,
                                            style: {
                                                opacity: 0.72,
                                                fontSize: 16,
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }
                                        }} />
                                }
                                {!collapsed && children && (
                                    <Icon
                                        name={childrenOpen[key]
                                            ? 'expandLess'
                                            : 'expandMore'}
                                    />)}
                            </ListItemButton>
                            {children && !collapsed && (
                                <Collapse
                                    in={childrenOpen[key]}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <RecursiveList
                                        items={children}
                                        onClick={onClick}
                                        collapsed={collapsed}
                                        sx={{ pl: collapsed ? 0 : 2, pb: 0 }}
                                        isSubmenu
                                    />
                                </Collapse>
                            )}
                            {!isSubmenu && (
                                <Box
                                    component={'div'}
                                    sx={{
                                        my: 1,
                                        borderBottom: '1.5px solid #A5CDAA',
                                    }}
                                />
                            )}
                        </ListItem>
                    );
                })}
            {collapsed && (
                <RecursiveMenu
                    items={dropItems || []}
                    onClick={onClick}
                    collapsed={false}
                    sx={{ pl: collapsed ? 0 : 2, pb: 0 }}
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    RecursiveList={RecursiveList}
                    onClose={() => {
                        setAnchorEl(null);
                        setDropItems(null);
                    }}
                    open={Boolean(anchorEl)}
                    slotProps={{ paper: { sx: { minWidth: 280 } } }}
                />
            )}
        </List>
    );
};

// const ListItemPropTypes = PropTypes.oneOfType([
//     PropTypes.shape({ element: PropTypes.node.isRequired }),
//     PropTypes.shape({
//         key: PropTypes.number.isRequired,
//         text: PropTypes.string.isRequired,
//         icon: Icon.propTypes.name,
//         ListItemProps: PropTypes.any,
//         ListItemButtonProps: PropTypes.any,
//         children: PropTypes.array,
//     }),
// ]);

// RecursiveList.propTypes = {
//     collapsed: PropTypes.bool,
//     items: PropTypes.arrayOf(ListItemPropTypes),
//     onClick: PropTypes.func,
//     mode: PropTypes.oneOf(['list', 'menu']),
// };

export default RecursiveList;
