
import clickItemBehavior from './behaviors/clickItemBehavior';
import layoutBehavior from './behaviors/layoutBehavior';

import account from './models/account';
import activity from './models/activity';
import budget from './models/budget';
import composition from './models/composition';
import contract from './models/contract';
import input from './models/input';
import invoice from './models/invoice';
import lead from './models/lead';
import notice from './models/notice';
import planning from './models/planning';
import task from './models/task';
import timesheet from './models/timesheet';
import transaction from './models/transaction';
import user from './models/user';

// macro registration callback
export default () => {
    // behaviors
    clickItemBehavior();
    layoutBehavior();

    // model organized macros
    account();
    activity();
    budget();
    composition();
    contract();
    input();
    invoice();
    lead();
    notice();
    planning();
    task();
    timesheet();
    transaction();
    user();
};
