import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const GoogleDriveIcon = (props) => {
    return (
        <SvgIcon {...props} >
            <svg 
                width="21" 
                height="18" 
                viewBox="0 0 21 18" 
                fill="currentColor" 
                xmlns="http://www.w3.org/2000/svg"
            >
                <path 
                    opacity="0.6" 
                    d="M19.322 14L17.59 17C17.4282 17.28 17.2009 17.5167 16.9277 17.6896C16.6544 17.8626 16.3433 17.9667 16.021 17.993L15.8569 18H4.30995C4.06095 18 3.81995 17.954 3.59395 17.867L3.42895 17.795L5.61895 14H19.322ZM6.66095 0.339L8.92895 4.268L2.07895 16.134L0.267949 13C0.0924126 12.696 0 12.3511 0 12C0 11.6489 0.0924126 11.304 0.267949 11L6.04195 1C6.19795 0.73 6.41095 0.506 6.66095 0.339ZM12.3929 2.58706e-07C12.7163 -0.000164202 13.0349 0.0780853 13.3214 0.228042C13.6079 0.377998 13.8538 0.59519 14.0379 0.861L14.124 1L19.8979 11C20.0469 11.258 20.1339 11.54 20.1579 11.828L20.166 12H15.7019L8.77395 2.58706e-07H12.3929ZM10.0839 6.268L13.3939 12H6.77395L10.0839 6.268Z" 
                    fill="currentColor"
                />
            </svg>
        </SvgIcon>
    );
};

export default GoogleDriveIcon;