import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const LinkedInIcon = (props) => {
    return (
        <SvgIcon {...props} >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M1.77778 0C0.795556 0 0 0.795556 0 1.77778V14.2222C0 15.2044 0.795556 16 1.77778 16H14.2222C15.2044 16 16 15.2044 16 14.2222V1.77778C16 0.795556 15.2044 0 14.2222 0H1.77778ZM1.77778 1.77778H14.2222V14.2222H1.77778V1.77778ZM4.24826 2.94792C3.48649 2.94792 3.02951 3.40585 3.02951 4.01562C3.02951 4.6254 3.48654 5.0816 4.17188 5.0816C4.93365 5.0816 5.39062 4.6254 5.39062 4.01562C5.39062 3.40585 4.9336 2.94792 4.24826 2.94792ZM3.09028 6.22222V12.4444H5.33333V6.22222H3.09028ZM7.18403 6.22222V12.4444H9.42708V9.0434C9.42708 8.03096 10.1494 7.88542 10.3663 7.88542C10.5832 7.88542 11.1632 8.10296 11.1632 9.0434V12.4444H13.3333V9.0434C13.3333 7.09051 12.4655 6.22222 11.3802 6.22222C10.2949 6.22222 9.71597 6.58361 9.42708 7.09028V6.22222H7.18403Z" fill="#191919"/>
            </svg>
        </SvgIcon>
    );
};

export default LinkedInIcon;