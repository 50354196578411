
import { config } from '@arandu/laravel-mui-admin';


export const colors = (constant) => {
    if (typeof constant === 'string') {
        constant = parseInt(constant);
    }

    const taskStatuses = config('boot.data.constants.taskStatuses');
    const status = taskStatuses[constant];

    switch (status) {
    case 'Backlog':
        return {
            color: '#A626D3',
            background: '#F8E8FF',
        };
    case 'Sprint Atual':
        return {
            color: '#FA510C',
            background: '#FFE8D5',
        };
    case 'Execução':
        return {
            color: '#FF2C2C',
            background: '#FFDFDF',
        };
    case 'Revisão de Código':
        return {
            color: '#191919',
            background: '#F8F5C9',
        };
    case 'QA':
        return {
            color: '#0288D1',
            background: '#E0F2FE',
        };
    case 'Pronto':
        return {
            color: '#42774A',
            background: '#E6F2E7',
        };
    case 'Aprovação Cliente':
        return {
            color: '#1D9798',
            background: '#E9F0F1',
        };
    default:
        return {
            color: '#969696',
            background: '#F0F0F0',
        };
    }
};

export const icon = (constant) => {

    const taskStatuses = config('boot.data.constants.taskStatuses');
    const status = taskStatuses[constant];

    switch (status) {
    case 'Backlog':
        return 'assignment';
    case 'Sprint Atual':
        return 'flash_on';
    case 'Execução':
        return 'play_arrow';
    case 'Validação':
        return 'check';
    case 'Finalizado':
        return 'done';
    }
};
