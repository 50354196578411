import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const YouTubeIcon = (props) => {
    return (
        <SvgIcon {...props} >
            <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.5 6.85059L7.875 8.35059V5.35059L10.5 6.85059Z" fill="#19191990" stroke="#19191990" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M1.5 7.38139V6.31939C1.5 4.14814 1.5 3.06214 2.17875 2.36389C2.85825 1.66489 3.92775 1.63489 6.066 1.57414C7.0785 1.54564 8.1135 1.52539 9 1.52539C9.88575 1.52539 10.9207 1.54564 11.934 1.57414C14.0722 1.63489 15.1417 1.66489 15.8205 2.36389C16.5 3.06214 16.5 4.14889 16.5 6.31939V7.38064C16.5 9.55264 16.5 10.6379 15.8212 11.3369C15.1417 12.0351 14.073 12.0659 11.934 12.1259C10.9215 12.1551 9.8865 12.1754 9 12.1754C8.02182 12.1725 7.04372 12.156 6.066 12.1259C3.92775 12.0659 2.85825 12.0359 2.17875 11.3369C1.5 10.6379 1.5 9.55189 1.5 7.38139Z" stroke="#19191990" strokeWidth="1.5"/>
            </svg>
        </SvgIcon>
    );
};

export default YouTubeIcon;